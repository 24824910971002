// .columns

main#blog-listings-wrap {
    div, h2, p, figure {
        margin:0;
        padding:0;
    }
    margin: 0px auto;
    padding: 2rem;
    padding-top:0;
    @include bp(tablet-up) {
        display: flex;
    }
    section.main-column {
        flex: 1;
        flex-direction: column;
        @include bp(tablet-up) {
            flex: 3;
        }
    }
    aside.offers {
        flex: 1;
        > * {
            margin: 1rem .55555rem;
            text-align: center;
        }
    }
    .article {
        flex: 1;
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        flex-basis: auto;
        background: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        color: $gray-600;
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        @include bp(tablet-up) {
            flex-direction: row;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        &:hover, &:focus {
            color: $gray-800;
            box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25), 0 0px 0px rgba(0, 0, 0, 0.22);
            color: #444;
        }
        &__image {
            background: #eee;
            display: block;
            padding-top: 50%;
            position: relative;
            width: 100%;
            background-repeat: no-repeat;
            background-size:cover;
            @include bp(tablet-up) {
                min-height: 200px;
                order: 2;
                padding-top: 0;
                width: 300px;
            }
            img {
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
        &__body {
            display:flex;
            flex: 1;
            flex-direction: column;
            padding: 1.11111rem;
            @include bp(tablet-up) {
                flex: 1;
            }
        }
        &__title {
            color: $gray-800;
            flex-shrink: 0;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1;
            a { text-decoration: none; font-size:1.4rem;}
        }
        &__content {
            flex: 1;
            margin-top: 0.27777rem;
            font-size: .8rem;
            opacity: 1;
        }
        a { text-decoration: none;  }

    }
}


// .blog-page-wrap {
//     padding-top: 2rem;
//     @include bp(tablet-up) {
//         display: flex;
//         max-width: 77.222rem;
//         margin: 0 auto;
//     }
// }
// #blog-listings {
//     padding: 0rem .5rem;
//     margin: 0px auto;
//     flex: 1 1 75%;
//     @include bp(tablet-up) {
//         padding: 0rem 1.5rem;
//     }

//     .articleListing {
//         display: flex;
//         margin: 0 auto 2rem;
//         max-width: 30rem;
//         min-height: 200px;
//         flex-direction: column;
//         position: relative;
//         border-radius: 2px;
//         box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);

//         @include bp(tablet-up) {
//             max-width: 47.222rem;
//             flex-direction: row;
//             flex-wrap: no-wrap;
//             align-items: flex-start;
//             box-shadow: none;
//         }
//     }
//     .articleListing__image {
//         height: 176px;

//         @include bp(tablet-up) {
//             flex: 0 1 50%;
//         }
//     }
//     .articleListing__content {
//         order: 2;
//         padding: 1rem;
//         max-width: 30rem;

//         @include bp(tablet-up) {
//             order: inherit
//         }
//     }
//     .articleListing__title {
//         margin-top: 0;
//         line-height: 0;

//         a {
//             font-size: 1rem;
//             text-decoration: none;
//             cursor: pointer;
//             transition: all .25s;

//             &:hover {
//                 color: $gray-700;
//             }
//         }
//     }
//     .articleListing__teaser {
//         font-size: .865rem;

//         @include bp(tablet-up) {
//             margin-bottom: 0;
//         }
//     }
//     .articleListing__image {
//         // flex: 1;
//         min-width: 0;
//         position: relative;

//         .articleListing__shareWrapper {
//             color: white;
//             text-align: center;
//             opacity: 0;
//             transition: .3s all;
//             position: absolute;
//             top: 0;
//             left: 0;
//             bottom: 0;
//             width: 100%;
//             background: rgba(0, 0, 0, 0.2);
//             margin: auto;
//             align-items: center;

//             h2 {
//                 line-height: 0;
//             }
//             .share-widget {
//                 flex-wrap: wrap;
//                 max-width: 130px;
//                 margin: auto;

//                 .social-icon {
//                     flex-shrink: 0;
//                     margin-bottom: 15px;
//                 }
//             }
//         }
//         img {
//             max-width: 100%;
//             height: auto;
//             transition: .3s all;
//         }
//         &:hover {
//             .articleListing__shareWrapper {
//                 opacity: 1;
//                 z-index: 2;
//             }
//             img {
//                 -webkit-filter: blur(2px);
//                 filter: blur(2px);
//             }
//         }
//     }
// }
