.o-sitemap {
  display: flex;
  flex-wrap: wrap;
  &__nav {
    width: 50%;
    @include bp(tablet-up) {
      width: auto;
    }
  }
  &__title {
    font-weight: 700;
    color: black;
    padding: 0.5rem 0;
    display: block;
    text-decoration: none;
    &--secondary {
      border-bottom: none;
    }
    @include bp(tablet-up) {
      border-bottom: none;
    }
  }
  &__link {
    padding: 0.2rem 0;
    font-size:0.8rem;
    display: block;
    text-decoration: none;
  }
  &__secondary-nav {
    display:flex;
    flex-wrap:wrap;
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    margin-top: 1rem;
    &__title {
      width:50%;
      text-decoration: none;
      padding: 0.2rem 0;
      display: block;
      font-size:0.8rem;
      font-weight:500;
      @include bp(tablet-up) {
        width:auto;
        padding: 0.5rem 0;
        font-size:0.9rem;

      }
    }
  }

}
//
#c-footer {
  .o-sitemap {
      &__title {
        color: #888;
      }
  }
}
