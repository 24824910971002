.vertical-lander .c-secondary-nav {
  width: 100%;
  box-shadow: none;
  max-width: 1440px;
  padding: 0;
  overflow: visible;
  border-top: none;
  background-color: transparent;
  justify-content: center;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 30;

  &__cta {
    padding: 10px 0px 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .25);
    border-radius: 4px;
    background: #fff;
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    transition: none;
    text-align: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
    min-width: 125px;
    max-width: 400px;
    flex-basis: auto; /* default value */
    flex-grow: 1;

    &.articles {
      @extend %articles;

      &:hover {
        @extend %articles--white;
      }
    }
    &.compare {
      @extend %compare;

      &:hover {
        @extend %compare--white;
      }
    }
    &.reviews {
      @extend %reviews;

      &:hover {
        @extend %reviews--white;
      }
    }
    &.getaquote {
      @extend %getaquote;

      &:hover {
        @extend %getaquote--white;
      }
    }
  }
  &__image {
    width: 38px;
    height: 50px;

    > div {
      display: none;
    }
  }
  h3.h3 {
    color: $fg-accent;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 0;
    margin: 0 0 0 10px;
    text-align: center;
    letter-spacing: -0.73px;
  }
  p.p {
    display: flex;
    font-size: 16px;
    color: #32322C;
    font-weight: 300;
    letter-spacing: -0.17px;
    font-family: 'Roboto', sans-serif;
    background-color: #F8F8F8;
    min-height: 50px;
    line-height: 19px;
    border-top: 1px solid #D8D8D8;
    align-items: center;
    max-width: 100%;
    padding: 10px;
    margin: 10px 0 0;
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
  }
}
@include bp(tablet-up) {
  .vertical-lander .c-secondary-nav {
    display: flex;
    flex-wrap: nowrap;
    margin: -75px auto 25px;
    align-items: center;
    justify-content: center;

    &__cta {
      margin: 10px;
      text-align: center;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      align-items: center;
      padding: 20px 0px 0px;
      transition: background .25s, box-shadow .25s, color .25s;

      &:hover {
        box-shadow: 2px 4px 6px rgba(0, 0, 0, .25);
        background-color: $fg-accent;

        h3 {
          color: #FFFFFF;
        }
      }
    }
    p.p {
      min-height: 98px;
      margin: 20px 0 0;
    }
  }
}
@include bp(tablet-landscape-up) {
  .vertical-lander .c-secondary-nav {
    margin-bottom: 50px;

    &__image {
      width: 64px;
      height: 64px;
      margin-right: 0;
    }
    h3.h3 {
      font-size: 24px;
      max-width: 80%;
    }
    p.p {
      padding: 20px;
    }
  }
}
