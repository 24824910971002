.tiles-group {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  &.flexStart { justify-content: flex-start; }
  &.flexEnd { justify-content: flex-end }
  &.flexCenter { justify-content: center }
  &.flexSpaceBetween { justify-content: space-between }
  &.flexSpaceAround { justify-content: space-around }

  @include bp(tablet-down) {
    column-count: 1 !important;
  }

  .flexStart:not(.tile-with-icon), .flexEnd:not(.tile-with-icon), .flexCenter:not(.tile-with-icon), .flexSpaceBetween:not(.tile-with-icon), .flexSpaceAround:not(.tile-with-icon) {
    display: flex;
    height: 100%;
          &.alignCenter { align-items: center; }
      &.alignFlexStart { align-items: flex-start; }
      &.alignFlexEnd { align-items: flex-end; }
      &.alignStretch { align-items: stretch; }
      &.alignBaseline { align-items: baseline; }
      &.flexStart { justify-content: flex-start }
      &.flexEnd { justify-content: flex-end }
      &.flexCenter { justify-content: center }
      &.flexSpaceBetween { justify-content: space-between }
      &.flexSpaceAround { justify-content: space-around }
  }


  .tile-group {
    display: flex;
    width: 100%;

    ul {

      li {

        &.show-on-mobile {
          display:block;
          @include bp(tablet-up) {
            display:none;
          }
        }
        &.show-on-desktop {
          display:none;
          @include bp(tablet-up) {
            display:block;
          }
        }
      }
    }
  }
}
.ProductPageIconCopy {
  display: block;
  position: relative;
  padding: 8px;

  &.col-of-1 > .tile-with-icon {
    display: flex;
  }

  > .tile-with-icon {
    flex-direction: column;
    display: flex;
    align-items: center;
    height: 100%;
      &.alignCenter { align-items: center; }
      &.alignFlexStart { align-items: flex-start; }
      &.alignFlexEnd { align-items: flex-end; }
      &.alignStretch { align-items: stretch; }
      &.alignBaseline { align-items: baseline; }
      &.flexStart { justify-content: flex-start }
      &.flexEnd { justify-content: flex-end }
      &.flexCenter { justify-content: center }
      &.flexSpaceBetween { justify-content: space-between }
      &.flexSpaceAround { justify-content: space-around }

    @include bp(tablet-up) {
      &.right, &.left {
        flex-direction: row;
      }
      &.right > .icon, &.bottom > .icon {
        order: 2;
      }
    }
  }

  .icon {
    display: block;
    margin: 3%;
    height: $small-icon-size;
    width: $small-icon-size;
    min-height: 0;
    flex: 0 0 auto;

    &.medium {
      height: $medium-icon-size;
      width: $medium-icon-size;
      padding: 0px;
      min-height: 0;
    }
    &.large {
      height: $large-icon-size;
      width: $large-icon-size;
      padding: 0px;
      min-height: 0;
    }
    &.xlarge {
      height: $x-large-icon-size;
      width: $x-large-icon-size;
      padding: 0px;
      min-height: 0;
    }
  }
  .headline {
    font-weight: 500;
    font-family: $pp-serif-font;
    text-align: center;
    font-size: 19px;
    padding-bottom: 1.25rem;
    width: 100%;
  }
  .copy {
    font-family: 'Source Sans Pro', sans-serif;
    line-height: 1.5;
    white-space: pre-line;
    + center {
      margin-top: 1.5rem;
    }
  }
}
