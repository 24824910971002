#main-content.social-open {
  #simple-header {
    -webkit-filter: blur(10px);
    filter: blur(10px);
  }
  .social-icon {
    display: flex;
    opacity: 1;
    visibility: visible;
    flex: 0 0 45px;
  }
  .share-widget .social-icon:not(.mobile) {
    position: absolute;
    left: 50%;
    transition-duration: 500ms !important;
    opacity: 1;
    visibility: visible;
  }
  .social-icon:nth-child(1) {
    transform: rotate(190deg) translate(6.5em) rotate(-190deg);
  }
  .social-icon:nth-child(2) {
    transform: rotate(225deg) translate(5.5em) rotate(-225deg);
  }
  .social-icon:nth-child(3) {
    transform: rotate(270deg) translate(4em) rotate(-270deg);
  }
  .social-icon:nth-child(4) {
    transform: rotate(340deg) translate(3em) rotate(-340deg);
  }
}
#simple-header, .share-widget .social-icon {
  transition-duration: 500ms;
}
#full-article {
  word-wrap: break-word;
  margin-top: 1rem;

  ol {
    padding-left:1rem;
    li {
      counter-increment: step-counter;
      &::before {
          content: counter(step-counter);
          margin-right: 0.75rem;
          font-size: 80%;
          background-color: rgb(200,200,200);
          color: white;
          font-weight: bold;
          padding: 2px 8px;
          border-radius: 4px;
      }
    }
  }

  .article_listing {
    border-top: 1px solid #CCC;
    h2 {
      padding: 0;
      padding-top: 1rem;
      font-family: Raleway;
    }

    h3 {
      font-weight: 300;
      padding: 0;
      margin: 0;
    }

    .cta-container {
      display: flex;
      margin-bottom:1rem;
          .button, .altButton {
            height: 3rem;
            line-height:3rem;
            margin:.2rem;
            @include bp(tablet-up) {
              margin:1rem;
            }
          }
    }


    .full_review {
      font-weight: bold;
    }
    .article_listing_image {

      display: block;

      &.small-image {
        width: 15rem;
        height: 10rem;
        margin: 0 auto;
        margin-top:1rem;
      }
      &.large-image {
        width: 100%;
        height: 20rem;
        margin-top:1rem;
      }
    }

    .listing__rating {
      display: inline-flex;

      ul {
        padding-left: 0.5rem;
        padding-right: 1rem;
        margin: 0;
        li {
          list-style: none;
        }
      }
    }
  }


  .secondaryHeader {
    padding-bottom: 0.5rem;

    &.under {
      color: $margo;
      font-size: 1.75rem;
       @include bp(tablet-down) {
         font-size:1.4rem;
       }

    }
  }
  .author {
    display:flex;
    margin-bottom: 1rem;

    .author-pic {
      width: 3rem;
      height: 3rem;
      border-radius: 1.5rem;
      margin-right: 1rem;
    }
    .author-name-date-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .author-name {
      font-weight: bold;
      text-transform: uppercase;
      font-family: Raleway;
      font-size: 0.7rem;
    }
    .author-date {
      display: block;
      font-family: Sans-serif;
      font-size: 0.8rem;
      color: grey;
    }
  }
  .widgets {
    margin-right:1rem;
    display:none;
    min-width: 320px;
    iframe {
      margin-bottom: 1rem;
    }
  }
  .button {
    width: 100%;
    text-align: center;
    height: 3rem;
    background: $margo;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    &.var1 {
      background-color: #39A0ED !important;
    }

    &.var2 {
      background-color: #4B6287 !important;
    }

    &.var3 {
      background-color: #EDAE49 !important;
    }

    a {
      color: white;
      text-decoration: none;
      width: 100%;
      height: 100%;
      line-height: 3rem;
      cursor: pointer;
      border-bottom: none;
      text-decoration: none;
    }
    a:hover {
      background: none;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  a {
    color: $margo;
    cursor: pointer;
    border-bottom: 1px solid;
    text-decoration: none;
  }
  a:hover {
    background: rgba(2, 184, 117, 0.23);
  }

  h2 {
    padding: 2rem 0;
    padding-top:0;
    max-width:40rem;
    @include bp(tablet-down) {
      font-size:1.6rem;
    }
  }
  .article-content {
    margin: 0 auto;
    flex-grow: 0.5;
    max-width: calc(30rem + 2rem);
    padding: 0rem 1rem;
    width: 100%;
    .article-body-image {
      width:100%;
      max-width: 100%;
      margin-bottom:1rem;
      &.right {
        width: 40%;
        float: right !important;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
      }


      @include bp(tablet-up) {
        &.right {
          width: 50%;
          float: right;
          margin-top: 1rem;
          margin-left: 1rem;
        }
      }

    }

    p {
      max-width: 40rem;
    }
  }
  .share-widget {
    flex-direction: column;
    height: 3rem;
    margin-top: -3rem;
  }
  .social-icon:not(.mobile) {
    opacity: 0;
    visibility: collapse;
    position: absolute;
  }
  .social-icon.mobile {
    display: flex;
    opacity: 1;
    visibility: visible;
  }
  .social-icon:hover {
    cursor: pointer;
  }
  ul {
    padding-left: 1.666667rem;
    margin-top: .55556rem;
    margin-bottom: 1rem;
    max-width:30rem;

    li {
      list-style-type: initial;
    }
  }
}

@include bp(tablet-down) {
  #full-article {
    .article-content {
      img {
        float: none !important;
        padding-left: 0 !important;
        margin: auto;
        max-width: 80%;
      }
    }
  }
}

  @include bp(tablet-up) {
    #full-article {
      flex-direction: row;
      .widgets {
        display:flex;
        flex-direction: column;
      }


      .share-widget {
        position: absolute;
        margin-left: 1rem;
        height: 15rem;
        left: 0;
        top: 0;
      }
      .social-icon {
        flex: 0 0 45px;
        margin-bottom: .5rem;
      }
      .social-icon:not(.mobile) {
        display: flex;
        visibility: visible;
        position: relative;
        opacity: 1;
      }
      .social-icon.mobile {
        opacity: 0;
        visibility: collapse;
        position: absolute;
      }
      .article-content {
        max-width: calc(30rem + 14rem);
        padding: 0rem;
        padding-left: 2rem;
        padding-right: 2rem;


      }
    }
  }
