/* modal */
// body div#modal {
//   opacity: 0;
//   z-index: -10;
//   position: fixed;
//   width: 100%;
//   height: 100vh;
//   -webkit-transition: opacity 0.2s ease-in-out;
//   transition: opacity 0.2s ease-in-out;
//   -webkit-transition-delay: 0.2s;
//           transition-delay: 0.2s; }
//   body div#modal .modal-inner {
//     /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0b1e28+0,0b1e28+100,0b1e28+100&0.8+0,1+100 */
//     /* FF3.6-15 */
//     background: -webkit-radial-gradient(center, ellipse cover, rgba(11, 30, 40, 0.8) 0%, #0b1e28 100%);
//     /* Chrome10-25,Safari5.1-6 */
//     background: -webkit-radial-gradient(center ellipse, rgba(11, 30, 40, 0.8) 0%, #0b1e28 100%);
//     background: radial-gradient(ellipse at center, rgba(11, 30, 40, 0.8) 0%, #0b1e28 100%);
//     /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#cc0b1e28', endColorstr='#0b1e28',GradientType=1 );
//     /* IE6-9 fallback on horizontal gradient */
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: center;
//     -webkit-align-items: center;
//         -ms-flex-align: center;
//             align-items: center;
//     -webkit-box-pack: center;
//     -webkit-justify-content: center;
//         -ms-flex-pack: center;
//             justify-content: center;
//     height: 100vh; }
//     body div#modal .modal-inner .modal-content {
//       position: relative; }
//       body div#modal .modal-inner .modal-content i.close {
//         position: absolute;
//         top: -4rem;
//         right: -0.5rem;
//         font-size: 3rem;
//         color: #FFF;
//         cursor: pointer; }
//         @media all and (min-width: 69em) {
//           body div#modal .modal-inner .modal-content i.close {
//             right: -4rem; } }
//   body div#modal div.close {
//     position: absolute;
//     height: 100vh;
//     width: 100%;
//     cursor: pointer;
//     display: block;
//     top: 0;
//     z-index: -1; }
// body div#large-modal {
//   top: 0;
//   opacity: 0;
//   z-index: -10;
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0b1e28+0,0b1e28+100,0b1e28+100&0.8+0,1+100 */
//   /* FF3.6-15 */
//   background: -webkit-radial-gradient(center, ellipse cover, rgba(11, 30, 40, 0.8) 0%, #0b1e28 100%);
//   /* Chrome10-25,Safari5.1-6 */
//   background: -webkit-radial-gradient(center ellipse, rgba(11, 30, 40, 0.8) 0%, #0b1e28 100%);
//   background: radial-gradient(ellipse at center, rgba(11, 30, 40, 0.8) 0%, #0b1e28 100%);
//   /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#cc0b1e28', endColorstr='#0b1e28',GradientType=1 );
//   /* IE6-9 fallback on horizontal gradient */
//   -webkit-transition: opacity 0.2s ease-in-out;
//   transition: opacity 0.2s ease-in-out;
//   -webkit-transition-delay: 0.2s;
//           transition-delay: 0.2s; }
//   body div#large-modal .modal-inner {
//     /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#02b875+0,266990+100&1+0,0.94+1,0.94+100 */
//     /* FF3.6-15 */
//     background: -webkit-linear-gradient(-45deg, #02b875 0%, rgba(2, 183, 117, 0.94) 1%, rgba(38, 105, 144, 0.94) 100%);
//     /* Chrome10-25,Safari5.1-6 */
//     background: -webkit-linear-gradient(315deg, #02b875 0%, rgba(2, 183, 117, 0.94) 1%, rgba(38, 105, 144, 0.94) 100%);
//     background: linear-gradient(135deg, #02b875 0%, rgba(2, 183, 117, 0.94) 1%, rgba(38, 105, 144, 0.94) 100%);
//     /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#02b875', endColorstr='#f0266990',GradientType=1 );
//     /* IE6-9 fallback on horizontal gradient */ }
//   body div#large-modal .modal-content {
//     position: relative;
//     width: 100%;
//     max-width: 100rem;
//     height: 100%;
//     padding: 5rem 2rem;
//     margin: auto; }
//     body div#large-modal .modal-content i.close {
//       position: absolute;
//       top: 0.5rem;
//       right: 0.6rem;
//       font-size: 3rem;
//       color: #FFF;
//       cursor: pointer; }
//     @media all and (min-width: 48em) {
//       body div#large-modal .modal-content {
//         padding: 4rem; } }
//   body div#large-modal div.close {
//     position: absolute;
//     height: 100vh;
//     width: 100%;
//     cursor: pointer;
//     display: block;
//     top: 0;
//     z-index: -1; }
// body .privacy-modal {
//   display: none; }
// body .terms-modal {
//   display: none; }
// body.active-modal {
//   overflow: hidden; }
//   body.active-modal div#modal {
//     z-index: 20;
//     opacity: 1; }
// body.active-large-modal {
//   overflow: hidden; }
//   body.active-large-modal div#large-modal {
//     z-index: 10;
//     opacity: 1;
//     overflow: scroll; }
//   body.active-large-modal.privacy .privacy-modal {
//     display: block; }
//   body.active-large-modal.terms .terms-modal {
//     display: block; }
body.margo-one-page {
    #form-page {
        background: #f5f5f5;
    }

    form [data-page] {
        display: block;
        margin: 0.5rem 0 4rem;
        overflow: hidden;
        position: relative;
    }

    form [data-page] button,
    form [data-page] h3,
    form [data-page] h4,
    form [data-page] input,
    form [data-page] label,
    form [data-page] select,
    form [data-page] textarea {
        outline: none;
    }

    form [data-page] button,
    form [data-page] input,
    form [data-page] select,
    form [data-page] textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0 solid;
        border-radius: 0;
        font-size: 0.85rem;
        line-height: 1.7rem;
        font-weight: 500;
        background: transparent;
        color: #8c8c8c;
        font-weight: 300;
        letter-spacing: 0.05rem;
        outline: none;
        width: 100%;
        padding-right: 4rem;
        height: 4.5rem;
        vertical-align: middle;
    }

    form [data-page] .input,
    form [data-page] .select,
    form [data-page] .textarea {
        position: relative;
    }

    form [data-page] .textarea {
        padding-top: 0.9rem;
    }

    form [data-page] input,
    form [data-page] p,
    form [data-page] select {
        max-width: inherit;
        opacity: 1;
        margin: 0;
    }

    form [data-page] h4 {
        font-family: Roboto;
    }

    form [data-page] a {
        font-size: inherit;
        line-height: inherit;
        margin: 0;
        text-decoration: none;
        color: $margo;
    }

    form [data-page] textarea {
        padding-top: 0.75rem;
    }

    form [data-page] label {
        text-transform: uppercase;
        font-family: Roboto;
        font-weight: 700;
        font-size: 0.7rem;
        line-height: 1.5rem;
        color: #173238;
        position: absolute;
        top: 0.5rem;
        z-index: 2;
    }

    form [data-page] label.solo {
        line-height: 1.3rem;
        padding: 0.75rem 0;
    }

    form [data-page] label.car,
    form [data-page] label.email,
    form [data-page] label.house,
    form [data-page] label.person,
    form [data-page] label.phone {
        padding-left: 1.3rem;
    }

    form [data-page] label.car:before,
    form [data-page] label.email:before,
    form [data-page] label.house:before,
    form [data-page] label.person:before,
    form [data-page] label.phone:before {
        font-family: "Ionicons";
        content: '\f36f';
        position: absolute;
        left: 0;
        vertical-align: middle;
        padding-top: 0.15rem;
    }

    form [data-page] label.house:before {
        content: '\f448';
    }

    form [data-page] label.person:before {
        content: '\f3a0';
    }

    form [data-page] label.phone:before {
        content: '\f4b9';
    }

    form [data-page] label.email:before {
        content: '\f422';
    }

    form [data-page] label.formErrorBanner {
        background: #C83934;
        padding: 0.75rem;
        top: 0;
        width: 100%;
        position: relative;
        color: #FFF;
        font-weight: 100;
        display: block;
        text-transform: none;
    }
    @media all and (min-width: 48em) {
        form [data-page] label.formErrorBanner {
            padding: 0.75rem 2rem;
        }
    }

    form [data-page] h3 {
        margin: 0;
        position: relative;
        background: #FAFAFA;
        color: #8c8c8c;
        padding: 0.5rem 1rem;
        font-size: 0.85rem;
        line-height: 1.5rem;
        letter-spacing: 0.05rem;
        font-weight: 500;
    }

    form [data-page] h3:after {
        content: 'Required';
        position: absolute;
        text-transform: uppercase;
        top: 0.5rem;
        right: 0.75rem;
        background: #d1d1d1;
        border-radius: 0.2rem;
        color: #FFF;
        font-size: 0.75rem;
        padding: 0 0.75rem;
        line-height: 1.5rem;
        font-weight: 300;
    }
    @media all and (min-width: 48em) {
        form [data-page] h3 {
            padding: 0.75rem 2rem;
        }

        form [data-page] h3:after {
            top: 0.75rem;
        }
    }

    form [data-page] h4.solo {
        font-size: 0.6rem;
        line-height: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        padding: 0.8rem 0;
    }

    form [data-page] h4.solo.privacy {}

    form [data-page] h4.solo.privacy:before {
        content: '';
        font-family: "Material Icons";
        color: #02B875;
        font-size: 2rem;
        padding-top: 0.65rem;
        position: absolute;
        vertical-align: middle;
        left: 0;
    }

    form [data-page] .formly-field {
        border-bottom: 0.15rem solid #f0f0f0;
    }

    form [data-page] .formly-field.formly-field-hidden {
        border-bottom: 0 solid transparent;
    }

    form [data-page] .field.control-group {
        position: relative;
        padding-left: 0.75rem;
        overflow: hidden;
        height: 3.5rem;
    }

    form [data-page] .field.control-group.error:before,
    form [data-page] .field.control-group.success:before {
        position: absolute;
        content: '';
        width: 0.25rem;
        height: 100%;
        left: 0;
        top: 0;
    }

    form [data-page] .field.control-group.success:before {
        background: #02B875;
    }

    form [data-page] .field.control-group.error {
        background: #F9EBEA;
    }

    form [data-page] .field.control-group.error:before {
        background: #C83934;
    }

    form [data-page] .field.control-group.dollar-sign .select {
        padding-left: 1.5rem;
    }

    form [data-page] .field.control-group.dollar-sign .select:before {
        content: '$';
        position: absolute;
        font-size: 0.85rem;
        vertical-align: middle;
        font-weight: 300;
        left: 0;
    }

    form [data-page] .field.control-group.textarea {
        height: auto;
        padding-bottom: 0.75rem;
    }
    @media all and (min-width: 48em) {
        form [data-page] .field.control-group {
            padding-left: 2rem;
        }
    }
    @media all and (min-width: 69em) {
        form [data-page] .field.control-group {
            height: 3.5rem;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
        }

        form [data-page] .field.control-group.textarea {
            height: auto;
            min-height: 5rem;
        }

        form [data-page] .field.control-group label {
            padding-top: 0.40rem;
            font-size: 0.75rem;
            line-height: 1.7rem;
        }

        form [data-page] .field.control-group .input,
        form [data-page] .field.control-group .select,
        form [data-page] .field.control-group .textarea {
            width: calc(100% - 1rem);
        }

        form [data-page] .field.control-group .input input,
        form [data-page] .field.control-group .input select,
        form [data-page] .field.control-group .input textarea,
        form [data-page] .field.control-group .select input,
        form [data-page] .field.control-group .select select,
        form [data-page] .field.control-group .select textarea,
        form [data-page] .field.control-group .textarea input,
        form [data-page] .field.control-group .textarea select,
        form [data-page] .field.control-group .textarea textarea {
            padding-top: 0;
            padding-left: 13rem;
            height: 2rem;
        }
    }

    form [data-page][data-page='/consent'] .formly-field,
    form [data-page][data-page='/offers'] .formly-field,
    form [data-page][data-page='/privacy'] .formly-field,
    form [data-page][data-page='/submit'] .formly-field,
    form [data-page][data-page='edmunds'] .formly-field,
    form [data-page][data-page='form-welcome'] .formly-field {
        border-bottom: 0 solid transparent;
    }

    form [data-page][data-page='form-welcome'] {
        color: #02B875;
        text-align: center;
        padding: 2rem 0;
        margin: 0 auto;
        max-width: 26rem;
        border-radius: 0.3rem;
    }

    form [data-page][data-page='form-welcome'] i {
        font-size: 2rem;
    }

    form [data-page][data-page='form-welcome'] p {
        font-weight: 500;
        color: #02B875;
        font-size: 1.3rem;
        line-height: 1.8rem;
    }
    @media all and (min-width: 48em) {
        form [data-page][data-page='form-welcome'] {
            max-width: 100%;
            padding: 2rem;
            margin: 2rem auto;
            background: rgba(0, 171, 107, 0.1);
            border: 0.2rem solid #02B875;
        }
    }

    form [data-page][data-page="edmunds"] {
        margin: 0.5rem 0 1rem;
    }

    form [data-page][data-page="edmunds"] .edmunds {
        box-shadow: 0 0 0.2rem 0.1rem rgba(0, 0, 0, 0.04);
        border: 0.1rem solid #E4E4E4;
        border-radius: 0.3rem;
        background: #FFF;
        display: block;
    }
    @media all and (min-width: 48em) {
        form [data-page][data-page="edmunds"] .edmunds {
            display: none;
        }
    }

    form [data-page][data-page="edmunds"] .edmunds img {
        width: 50%;
        max-width: 20rem;
        margin: auto;
        display: block;
    }
    @media all and (min-width: 48em) {
        form [data-page][data-page="edmunds"] {
            margin: 0;
        }
    }

    form [data-page][data-page="/contact-us"],
    form [data-page][data-page="/driver-details"],
    form [data-page][data-page="/policy-details"] {
        box-shadow: 0 0 0.2rem 0.1rem rgba(0, 0, 0, 0.04);
        border: 0.1rem solid #E4E4E4;
        border-radius: 0.3rem;
        background: #FFF;
    }

    form [data-page][data-page="/driver-details"] .formly-field .form-complete-confirm {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: calc(100% - 1rem);
    }
    @media all and (min-width: 69em) {
        form [data-page][data-page="/driver-details"] .formly-field .form-complete-confirm {
            width: calc(100% - 2rem);
        }
    }

    form [data-page][data-page="/driver-details"] .formly-field .form-complete-confirm .ion-android-checkmark-circle {
        font-size: 2rem;
        line-height: 2rem;
        margin: auto;
        width: 1.7rem;
        display: block;
        color: #8c8c8c;
    }

    form [data-page][data-page="/consent"], form [data-page] .formly-field-TCPA,
    form [data-page][data-page="/driver-details"],
    form [data-page][data-page="/privacy"],
    form [data-page][data-page="/submit"] , form [data-page] .submit-btn  {
        margin: 0.5rem 0 2rem;
    }

    form [data-page][data-page="/consent"], form [data-page] .formly-field-TCPA, 
    form [data-page][data-page="/privacy"] {
        padding: 0 1rem;
        text-align: center;
        font-size: 0.8rem;
        line-height: 0.80rem;
    }

    form [data-page][data-page="/consent"] label, form [data-page] .formly-field-TCPA label,
    form [data-page][data-page="/consent"] p, form [data-page] .formly-field-TCPA p,
    form [data-page][data-page="/privacy"] label,
    form [data-page][data-page="/privacy"] p {
        color: #224952;
        position: relative;
        font-size: 0.6rem;
        line-height: 0.80rem;
        text-transform: none;
        font-weight: 300;
        top: 0;
        height: auto;
    }

    form [data-page][data-page="/submit"] , form [data-page] .submit-btn  {
        position: relative;
    }

    form [data-page][data-page="/submit"] button.btn , form [data-page] .submit-btn  button.btn {
        border: 0.2rem solid #02B875;
        background: transparent;
        border-radius: 0.2rem;
        padding: 0.5rem 0;
        font-weight: 600;
        margin: 0.3rem 0;
        text-align: center;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
        width: 15rem;
        cursor: pointer;
        color: #02B875;
        font-size: 0.75rem;
        display: block;
        height: 3.5rem;
        margin: 0 auto;
    }
    @media all and (min-width: 48em) {
        form [data-page][data-page="/submit"] button.btn , form [data-page] .submit-btn  button.btn {
            width: 15rem;
        }
    }

    form [data-page][data-page="/submit"] button.btn.active, form [data-page] .submit-btn  button.btn.active,
    form [data-page][data-page="/submit"] button.btn:active, form [data-page] .submit-btn  button.btn:active,
    form [data-page][data-page="/submit"] button.btn:hover , form [data-page] .submit-btn  button.btn:hover {
        background: #02B875;
        color: #FFF;
    }

    form [data-page][data-page="/submit"] button.btn.btn, form [data-page] .submit-btn  button.btn.btn,
    form [data-page][data-page="/submit"] button.btn.solid , form [data-page] .submit-btn  button.btn.solid {
        background: #02B875;
        color: #FFF;
    }

    form [data-page][data-page="/submit"] button.btn.btn.active, form [data-page] .submit-btn  button.btn.btn.active,
    form [data-page][data-page="/submit"] button.btn.btn:active, form [data-page] .submit-btn  button.btn.btn:active,
    form [data-page][data-page="/submit"] button.btn.btn:hover, form [data-page] .submit-btn  button.btn.btn:hover,
    form [data-page][data-page="/submit"] button.btn.solid.active, form [data-page] .submit-btn  button.btn.solid.active,
    form [data-page][data-page="/submit"] button.btn.solid:active, form [data-page] .submit-btn  button.btn.solid:active,
    form [data-page][data-page="/submit"] button.btn.solid:hover , form [data-page] .submit-btn  button.btn.solid:hover {
        border-color: #02d185;
        background: #02d185;
    }

    form [data-page][data-page="/submit"]:after, form [data-page] .submit-btn :after,
    form [data-page][data-page="/submit"]:before , form [data-page] .submit-btn :before {
        height: 0.2rem;
        background: #02B875;
        content: '';
        position: absolute;
        width: calc(50% - 12rem);
        top: 1.75rem;
        display: block;
    }

    form [data-page][data-page="/submit"]:before , form [data-page] .submit-btn :before {
        left: 2.5rem;
    }

    form [data-page][data-page="/submit"]:after , form [data-page] .submit-btn :after {
        right: 2.5rem;
    }

    form [data-page][data-page="/privacy"] {
        margin-bottom: 0;
        padding-bottom: 4rem;
    }

    form.ng-valid [data-page="/driver-details"] .field.control-group:last-of-type .ion-android-checkmark-circle {
        color: #02B875;
    }

    input[type="text"]:focus {
        color: #5e5e5e;
    }

    input[type="text"]:focus::-webkit-input-placeholder {
        text-transform: none;
    }

    input[type="text"]:focus:-moz-placeholder {
        /* Firefox 18- */
        text-transform: none;
    }

    input[type="text"]:focus::-moz-placeholder {
        /* Firefox 19+ */
        text-transform: none;
    }

    input[type="text"]:focus:-ms-input-placeholder {
        text-transform: none;
    }

    .select {
        position: relative;
        height: 2rem;
    }

    .select:after {
        font-family: "Material Icons";
        content: 'expand_more';
        color: #8c8c8c;
        position: absolute;
        right: 2rem;
        bottom: 0.5rem;
        z-index: 1;
    }

    .select select {
        position: absolute;
        z-index: 2;
    }

    .select select::-ms-expand {
        display: none;
    }
    @media all and (min-width: 48em) {
        .form-content-wrapper {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            max-width: 70rem;
            margin: 0 auto;
        }
    }

    .form-content-wrapper .form-wrapper {
        margin-top: 1.5rem;
        padding: 0 1rem;
        display: block;
        width: 100%;
    }
    @media all and (min-width: 48em) {
        .form-content-wrapper .form-wrapper {
            padding: 0 0 0 2rem;
            max-width: 60%;
            display: inline-block;
        }
    }
    @media all and (min-width: 69em) {
        .form-content-wrapper .form-wrapper {
            padding: 0 2rem;
        }
    }

    .form-content-wrapper .sidebar {
        display: none;
    }
    @media all and (min-width: 48em) {
        .form-content-wrapper .sidebar {
            display: inline-block;
            width: 40%;
        }

        .form-content-wrapper .sidebar section {
            box-shadow: 0 0 0.2rem 0.1rem rgba(0, 0, 0, 0.04);
            border: 0.1rem solid #E4E4E4;
            border-radius: 0.3rem;
            background: #FFF;
            margin: 2rem;
            padding: 4rem 2rem;
        }

        .form-content-wrapper .sidebar section h2,
        .form-content-wrapper .sidebar section h3,
        .form-content-wrapper .sidebar section h4 {
            color: #8c8c8c;
            font-weight: 200;
        }

        .form-content-wrapper .sidebar section h2 {
            font-size: 2rem;
            line-height: 2.4rem;
            vertical-align: middle;
            text-align: center;
        }

        .form-content-wrapper .sidebar section h3 {
            font-size: 1.5rem;
            line-height: 1.8rem;
            padding: 0.5rem 0;
            text-align: center;
        }

        .form-content-wrapper .sidebar section h4 {
            position: relative;
            padding-bottom: 1rem;
            text-transform: uppercase;
            font-size: 0.75rem;
            line-height: 1.25rem;
            font-family: Raleway;
        }

        .form-content-wrapper .sidebar section h4:after {
            height: 0.1rem;
            width: calc(100% + 4rem);
            position: absolute;
            content: '';
            background: #f0f0f0;
            left: -2rem;
            top: 2rem;
            display: block;
        }

        .form-content-wrapper .sidebar section:first-of-type {
            padding: 0.75rem 2rem;
        }

        .form-content-wrapper .sidebar section:first-of-type p {
            padding: 1rem 0 0 2rem;
            margin: 0;
            position: relative;
            vertical-align: middle;
            font-size: 0.85rem;
            max-width: 24rem;
        }

        .form-content-wrapper .sidebar section:first-of-type p:before {
            font-family: "Material Icons";
            content: 'circle';
            position: absolute;
            left: -0.15rem;
            font-size: 0.85rem;
        }

        .form-content-wrapper .sidebar section:first-of-type p.active {
            color: #02B875;
            padding-left: 2rem;
            font-size: 0.85rem;
        }

        .form-content-wrapper .sidebar section:first-of-type p.active:before {
            font-size: 1.5rem;
            content: 'check_circle';
            font-family: "Material Icons";
            left: -0.5rem;
            top: 0.5rem;
        }

        .form-content-wrapper .sidebar section.reviews {
            padding: 0.75rem 2rem;
        }

        .form-content-wrapper .sidebar section.reviews #RRTestimonials {
            padding: 0.75rem 0;
        }

        .form-content-wrapper .sidebar section.edmunds {
            padding: 0.75rem;
        }

        .form-content-wrapper .sidebar section.edmunds img {
            margin: auto;
            display: block;
            max-width: 16rem;
        }

        .form-content-wrapper .sidebar section.video-cta a.ion-play {
            display: block;
            text-align: center;
            font-size: 3rem;
        }
    }

    #eo-interstitial {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        height: calc(100vh - 15rem);
        width: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-animation: spin 1s infinite;
        animation: spin 1s infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        color: #266990;
    }

    #eo-interstitial:before {
        content: '\f29C';
        font-family: "Ionicons";
        font-size: 36px;
        line-height: 1;
    }
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .cta-wrapper {
        margin: 0 1rem 2rem;
        padding: 2rem 0;
        background: #FFF;
        height: calc(100vh - 6rem);
        box-shadow: 0 0 0.5rem 0.2rem rgba(0, 0, 0, 0.19);
    }

    .cta-wrapper .cta {
        margin: 0 2rem;
        padding: 0.3rem;
        height: 100%;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#02b875+0,266990+100 */
        background: #02b875;
        /* Old browsers */
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, #02b875 0%, #266990 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: -webkit-linear-gradient(315deg, #02b875 0%, #266990 100%);
        background: linear-gradient(135deg, #02b875 0%, #266990 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#02b875', endColorstr='#266990',GradientType=1 );
        /* IE6-9 fallback on horizontal gradient */
        box-shadow: inset 0 0 0.15rem #224952;
    }

    .cta-wrapper .cta .inner {
        position: relative;
        overflow-x: hidden;
        padding: 1.5rem;
        background: #FFF;
        height: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        box-shadow: 0 0 0.15rem #224952;
    }

    .cta-wrapper .cta .inner .content {
        padding-bottom: 3rem;
    }

    .cta-wrapper .cta .inner .content h1 {
        width: 100%;
        max-width: 44rem;
        font-size: 3rem;
        line-height: 3.5rem;
        margin-bottom: 3rem;
    }
    @media all and (min-width: 48em) {
        .cta-wrapper .cta .inner .content h1 {
            max-width: 54.16rem;
            font-size: 3.8rem;
            line-height: 4.3rem;
            margin-bottom: 2rem;
        }
    }
    @media all and (min-width: 69em) {
        .cta-wrapper .cta .inner .content h1 {
            max-width: 57.16rem;
            font-size: 4.2rem;
            line-height: 4.7rem;
            margin-bottom: 2rem;
        }
    }
    @media all and (min-width: 90em) {
        .cta-wrapper .cta .inner .content h1 {
            max-width: 66.66rem;
            font-size: 5rem;
            line-height: 5.5rem;
            margin-bottom: 2rem;
        }
    }

    .cta-wrapper .cta .inner .content a {
        display: inline-block;
        border: 0.2rem solid #02B875;
        background: transparent;
        border-radius: 0.2rem;
        padding: 0.5rem 0;
        margin: 0.3rem 0;
        text-align: center;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
        width: 100%;
        cursor: pointer;
        color: #02B875;
        font-size: 0.75rem;
    }
    @media all and (min-width: 48em) {
        .cta-wrapper .cta .inner .content a {
            width: 12rem;
        }
    }

    .cta-wrapper .cta .inner .content a.active,
    .cta-wrapper .cta .inner .content a:active,
    .cta-wrapper .cta .inner .content a:hover {
        background: #02B875;
        color: #FFF;
    }

    .cta-wrapper .cta .inner .content a.btn,
    .cta-wrapper .cta .inner .content a.solid {
        background: #02B875;
        color: #FFF;
    }

    .cta-wrapper .cta .inner .content a.btn.active,
    .cta-wrapper .cta .inner .content a.btn:active,
    .cta-wrapper .cta .inner .content a.btn:hover,
    .cta-wrapper .cta .inner .content a.solid.active,
    .cta-wrapper .cta .inner .content a.solid:active,
    .cta-wrapper .cta .inner .content a.solid:hover {
        border-color: #02d185;
        background: #02d185;
    }

    .cta-wrapper .cta .inner .content a:first-of-type {
        margin-right: 0.75rem;
    }
    @media all and (min-width: 48em) {
        .cta-wrapper .cta .inner .content {
            padding-bottom: 2rem;
        }
    }

    .cta-wrapper .cta .inner .scroll-indicator {
        position: absolute;
        bottom: 0.75rem;
        left: calc(50% - 1rem);
        font-size: 3rem;
        -webkit-animation: fade-down 0.75s forwards;
        animation: fade-down 0.75s forwards;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-delay: 2.5s;
        animation-delay: 2.5s;
        opacity: 0;
    }

    .cta-wrapper .cta .inner .scroll-indicator:before {
        color: #02B875;
    }
    @keyframes fade-down {
        0% {
            opacity: 0;
            bottom: 5rem;
        }

        100% {
            opacity: 1;
            bottom: 1.5rem;
        }
    }
    @-webkit-keyframes fade-down {
        0% {
            opacity: 0;
            bottom: 5rem;
        }

        100% {
            opacity: 1;
            bottom: 1.5rem;
        }
    }

    .cta-wrapper .bottom-text-wrapper {
        position: relative;
        height: 2rem;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .cta-wrapper .bottom-text-wrapper .text {
        font-size: 0.8rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
        color: #02B875;
        font-family: "acumin-pro", sans-serif;
    }
    @media all and (min-width: 48em) {
        .cta-wrapper {
            margin: 0 3rem 3rem;
            height: calc(100vh - 7rem);
        }
    }

    .thankyou-wrapper {
        padding-top: 2rem;
    }
    @media all and (min-width: 48em) {
        .thankyou-wrapper {
            padding-top: 0;
            height: calc(100vh - 15rem);
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            min-height: 39rem;
        }
    }

    .thankyou-wrapper .inner {
        text-align: center;
        max-width: 50rem;
        margin-bottom: 5rem;
    }

    .thankyou-wrapper h2 {
        color: #02B875;
        margin: 0px auto;
        margin-bottom: 2rem;
    }

    .thankyou-wrapper h4,
    .thankyou-wrapper p {
        margin-bottom: 4rem;
    }
}
