$footer-max-width: 1120px;

#c-footer {
  padding: 3rem 0 0;
  background-color: #484349;
  flex-shrink: 0;

  .main-footer {
    max-width: $footer-max-width;
    margin: 0 auto;
    padding: 0 16px;

    @include bp(desktop-up) {
      display: flex;
      padding: 0;
    }
  }
  .logo-share-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include bp(tablet-up) {
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
    }
    @include bp(desktop-up) {
      flex-direction: column;
      flex: 1 0 auto;
      justify-content: flex-start;

      > .o-share-shell {
        margin-top: 8px;
        width: 200px;

        .social-widget {
          justify-content: space-around;
        }
      }
    }
  }
  .footer-logo {
    position: relative;
    width: 200px;

    a {
      display: block;
      height: 100%;
      width: 100%;
      svg {
        width: 100%;
      }
    }
  }
  .social-widget {
    padding-bottom: 16px;
    justify-content: space-between;
      a {
      margin: 0 16px 0 0;
    @include bp(desktop-up) {
      margin-right: 0;
}
      &:last-child {
        margin-right: 0;
      }
    }
    @include bp(desktop-up) {
      padding-bottom: 0;
    }

  }
  .o-sitemap {
    max-width: 588px;
    margin: 0px auto;
    flex-wrap: wrap-row;
    justify-content: space-around;

    @include bp(tablet-up) {
      max-width: 100%;
      justify-content: space-between;
    }
    @include bp(desktop-up) {
      flex: 1 0 75%;
      justify-content: space-around;
      padding: 0;
    }
    &__nav {
      padding: 8px 0px;
      flex: 0 1 100%;

      @include bp(large-mobile-up) {
        flex-basis: 34%;
      }
      @include bp(tablet-up) {
        flex-basis: auto;
        flex-wrap: nowrap;
        justify-content: space-around;
      }
      @include bp(desktop-up) {
        padding: 0;
      }
      > a {
        position: relative;
        left: 20%;

        @include bp(tablet-up) {
          left: auto;
        }
      }
    }
    &__title {
      padding: 0;
      opacity: .75;
      font-family: 'Open Sans', sans-serif;
      font-size: .8625rem;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    &__link {
      padding: 0;
      font-size: .8625rem;
      color: #DDDCDD;
      line-height: 28px;
    }
    &__secondary-nav {
      border: none;
      align-items: center;
      justify-content: stretch;
      font-size: 10px;
      flex-wrap: nowrap;
      margin: 0 auto;
      @include bp(tablet-up) {
        margin: 0;
        flex: 1 0 70%;
      }
      a {
        display: block;
        font-weight: 400;
        width: auto;
        font-size: 12px;
        line-height: 16px;
        flex: 1 0 auto;
        padding: 0px 4px;
        border-right: 1px solid currentColor;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .footer-disclosure.small {
    @include bp(tablet-up) {
      max-width: 400px;
      margin: 0 auto;
      margin-top: -124px;
      margin-bottom: -46px;//
    }
  }
  .footer-disclosure p,   .footer-disclosure p a{
    color: #79767A;
    margin: 0 auto;
    max-width: $footer-max-width;
    font-size: 13px;
    line-height: 1.5;
    padding: 24px 16px 40px;
    font-family: 'Open Sans', sans-serif;
    white-space: pre-wrap;
    margin:0;
    text-align:center;
    a {
      padding: 0;
    }
  }
  .c-footer__bottom {
    color: #585459;
    padding: .5rem;
    text-align: center;
    font-size: 12px;
    border-bottom: 4px solid #3B373C;
    background-color: #2E2B2F;

    div {
      margin: 0 auto;
      max-width: $footer-max-width;

      @include bp(tablet-up) {
        align-items: center;
        justify-content: space-between;
        display: flex;
      }
    }
  }
  .footer-copyright {
    margin-bottom: 0;
    font-size: 12px;
    flex: 1 1 auto;
    line-height: 24px;
    color: currentColor;
    @include bp(tablet-up) {
      text-align: left;
    }
  }
}
