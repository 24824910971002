.ProductPageTestimonialContainer {
  &.col-count-1, &.col-count-2, &.col-count-3, &.col-count-4, &.col-count-5, &.col-count-6, &.col-count-7, &.col-count-8, &.col-count-9, &.col-count-10 {
    column-count: 1;
  }
  @include bp(tablet-up) {
    display: block;
    column-gap: 0;

    &.col-count-2 {
      column-count: 2;
    }
    &.col-count-3 {
      column-count: 3;
    }
    &.col-count-4 {
      column-count: 4;
    }
    &.col-count-5 {
      column-count: 5;
    }
    &.col-count-6 {
      column-count: 6;
    }
    &.col-count-7 {
      column-count: 7;
    }
    &.col-count-8 {
      column-count: 8;
    }
    &.col-count-9 {
      column-count: 9;
    }
    &.col-count-10 {
      column-count: 10;
    }
  }
}
.ProductPageTestimonial {
  padding: 8px;
  break-inside: avoid;
  column-break-inside: avoid;
  page-break-inside: avoid;
  width: 100%;

  .testimonial {
    font: normal 16px/1.4 'Roboto';
    border-radius: 8px;
    box-shadow: 0 7px 18px -8px rgba(0, 0, 0, 0.23);
    margin-bottom: 24px;
    position: relative;
    background-color: lighten($azureish-white, 5%);
    display: inline-block;
    padding: 16px;

    &:before {
      border-top: 25px solid rgba(0, 0, 0, 0.04);
      border-left: 0px solid rgba(0, 0, 0, 0);
      border-right: 25px solid rgba(0, 0, 0, 0);
      content: "";
      display: inline-block;
      position: absolute;
      left: 40px;
      bottom: -26px;
    }
    &:after {
      border-top: 25px solid lighten($azureish-white, 5%);
      border-left: 0 solid rgba(0, 0, 0, 0);
      border-right: 25px solid rgba(0, 0, 0, 0);
      content: "";
      display: inline-block;
      position: absolute;
      left: 41px;
      bottom: -23px;
    }
  }
  p {
    font-size: 15px;

    .person, .location {
      font-size: 17px;
      font-family: $pp-serif-font;
    }
  }
}
