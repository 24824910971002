.btn {
  box-sizing: border-box;
  font-weight: 300;
  outline: 0;
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-size: 21px;
  line-height: 46.2px;
  background: #ff8901;
  margin: 38.5px auto 28px;
  width: 95%;
  max-width: 560px;
  display: block;
  @include border-radius(2px);
  @include transition(box-shadow 0.25s, background 0.25s, transform 0.25s);

  &:hover {
    cursor: pointer;
    box-shadow: 0 14px 21px -7px rgba(0, 0, 0, 0.3);
  }

  &.next {
    overflow: hidden;
    background: $submit;
    border: 0;
    padding: 2.03px 42px;
    margin-top: 28px;
    @include breakpoint(768) {
      width: auto;
    }
    .page[data-page='/lander'] & {
      width: 95%;
      max-width: 400px;
      font-weight: 600;
    }
  }
  &.back {
    background: #fff;
    color: rgba(102, 102, 102, 0.666);
    font-size: 14px;
    border: 1px solid;
    line-height: 14px;
    padding: 7px 21px;
    width: 105px;
    margin-top: 14px;
    &:hover {
      color: #d65b59;
    }
  }
}
