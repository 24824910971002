.c-swiper-planet-shell {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    display: block;
}

.swiper-container {
    width: 100%;
    height: 100%;

    h1 {
        color: white;
        font-weight: 800;
        margin: 0;
        text-align: left;
        text-transform: uppercase;
    }

    .eyebrow {
        color: white;
        text-align: left;
        display:block;
        line-height: 1;
        text-transform: none;
        &:before,
        &:after {
          width:0;
        }
    }


}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 3rem;
    text-align: center;
    font-size: 18px;
    width: 100%;
    height: 14rem;

    div {
        width: 80%;
    }

    .header-ctas a {
        margin: 1rem 0 0;
    }
}

.c-swiper-planet-shell {
    height: 26rem;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-pagination-bullet {
        width: 1rem;
        height: 1rem;
        margin: 0 0.5rem;
        position: relative;
        h4,
        p {
          visibility: hidden;
        }
        @include bp(tablet-down) {
            background: #000!important;

            &.swiper-pagination-bullet-active {
                background: $color-primary!important;
            }
        }
    }
}

.c-planet-wrapper {
    height: 51.422rem;
    width: 53rem;
}

.c-planets {
    top: 145%;
}
@include bp(mobile-up) {
    .c-swiper-planet-shell {
        height: 32rem;
    }

    .c-planet-wrapper {
        height: 51.422rem;
        width: 53rem;
    }

    .c-planets {
        top: 152%;
    }
}

#c-planets-shell {
    overflow: hidden;
    position: absolute;
    height: 45.5rem;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 500ms;

    .c-planets {
        position: absolute;
        left: 50%;
        transition-duration: 1s;
        transition-timing-function: ease-in-out;
    }

    .c-planets[active-planet="1"] {
        transform: rotate(0deg);
    }

    .c-planets[active-planet="2"] {
        transform: rotate(-90deg);
    }

    .c-planets[active-planet="3"] {
        transform: rotate(-180deg);
    }

    .c-planets[active-planet="4"] {
        transform: rotate(-270deg);
    }

    .c-planet-wrapper {
        transform-origin: right bottom;
        position: absolute;
        bottom: -53%;
        right: 50%;
    }

    .c-planet {
        height: 100%;
        width: 100%;
    }

    .c-planet-wrapper:nth-child(1) {
        transform: rotate(45deg);
    }

    .c-planet-wrapper:nth-child(2) {
        transform: rotate(135deg);
    }

    .c-planet-wrapper:nth-child(3) {
        transform: rotate(225deg);
    }

    .c-planet-wrapper:nth-child(4) {
        transform: rotate(315deg);
    }

    .planet {
        position: absolute;
        top: 20rem;
        left: -5vw;
        width: 110px;
        height: 110px;
    }
}
@include bp(tablet-up) {
    .c-swiper-planet-shell {
        height: 36rem;
    }

    #c-planets-shell {
        height: 46rem;
    }

    .c-planet-wrapper {
        height: 96rem;
        width: 99.105rem;
    }

    .c-planets {
        top: 224%;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 3.8rem;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
        width: 6.5rem;
        height: 6.4rem;
        margin: 0 2rem;
        position: relative;
        transition: all 0.25s;
        opacity: 1;
        h4,
        p {
            color: $gray-700;
            font-family: "Roboto", sans-serif;
            text-align: center;
            font-size: 0.8rem;
            transition: all 0.25s;
            visibility: visible;
        }

        h4 {
            text-transform: uppercase;
            bottom: 2rem;
            width: 100%;
            text-align: center;
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        p {
            text-transform: none;
            margin-top: 0.5rem;
        }
    }

    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
        margin-top: -2rem;
        h4,
        p {
            bottom: -1.5rem;
            opacity: 1;
        }
    }
}
