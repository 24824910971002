.share-widget {
  display: flex;
  align-items: center;
  justify-content:space-between;
  height: 3rem;
}

.social-icon.mobile {
  display: none;
}

a.social-icon {
  border-bottom: none!important;
}
