.inner-main-content {
    color: #4D4D4D;
  p.accent, span.author-name {
    color: initial;
  }
  a.button > span, a.o-button--alt > span, a.o-button > span {
    color: inherit;
    text-decoration: initial;
  }
  .counter-headline {
    font-weight: 700;
  }
}
