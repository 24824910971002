.listing__rating {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  .listing__rating--name {
    @extend .p;
    @extend .c-listing-expanded__rating--name;
    text-transform: uppercase;
    font-weight: 600;
  }
  .listing__rating--value {
    @extend .c-listing-expanded__rating--value;
    @extend .p;
  }
}

.listing__badges {
  display: flex;
  text-align: center;
  flex-flow: row wrap;
  justify-content: space-around;
  padding-top: 1rem;
  .listing__badge {
    flex-basis: 33%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    &-image {
      width: 100px;
      height: 65px;
    }
  }
}
.listing__metric--mobile {
  display: flex;
  flex-wrap: wrap;
}
.listing__mobile-review {
  p.p {
  font-size: .75rem;

  }
  &--image {
    width: 75px;
    background-size: contain!important;
  }
  &--metrics {
    p {
      margin: 0;
    }
  }
    &--app-rating {
      display: flex;
      overflow: visible;
      position: relative;
      background-clip: content-box;
      background: $margo;
      height: 1.1112rem;
      margin-bottom: .25rem;
      svg {
        height: 20px;
        position: relative;
      }
  }
}
