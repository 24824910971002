.header-container {
  max-width: 60rem;
  margin: 0 auto;
  width: 100%;
  padding-left:1rem;
  padding-right:1rem;
  @include bp(tablet-up) {
    padding-left:2rem;
    padding-right:2rem;
  }
}

.countdown-header {
    height: 2.5rem;
    vertical-align: middle;
    text-align: center;
    padding: 0.7rem;
    font-size: 0.6rem !important;
    font-family: Sans-serif;
    background: #222;
    color: white;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp(tablet-up) {
      font-size: 1.2rem !important;
      padding: 0.4rem;

    }
    span {
      padding: 0.5rem;
      background: red;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      border-radius: 0.3rem;
      font-weight: 800;
      font-size: 1rem;
      margin: 0.2rem;
      @include bp(tablet-up) {
        font-size: 23px;
        margin: 0.4rem;

      }
    }
}
.header-wrapper {
  flex-direction: column;

  @include bp(tablet-up) {
    flex-direction: row;
  }
}
.widget-container {
  min-width: 300px;
  z-index:1000;
}

.header-inline-right-image {
  width: 100% !important;
  min-height: 11rem;
  @include bp(tablet-up) {
    width:66%;
  }
}

.header-ctas {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;

  &.sticky {
    justify-content: center;
    background: white;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    border-bottom:1px solid #CCC;

    @include bp(tablet-up) {
      flex-direction: row;
    }

    .cta.text {
      font-size: 1.5rem;
      font-family: Raleway;
      font-weight: 800;
      color:$margo;
      text-decoration: none;
    }
    h3 {
      font-family: Raleway;
      padding-right: 1rem;
      margin: 0;
    }

    &.stuck {
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 100;
      border-top: 0;

    }
  }

  .input-cta-container {
    z-index:1;
    @include bp(tablet-up) {
      display: flex;
    }
  }
  input {
    border: 1px solid #ccc;
    height: 2.7rem;
    font-size: 1.3rem;
    width: 12rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    z-index: 1;
    @include bp(tablet-up) {
      margin:0;
    }
  }
}

.header-ctas a {
  display: flex;
  margin-left:0.75rem;
  margin-right:0.75rem;
  align-items: center;
}

.advertiser-disclosure {
  position: absolute;
  top: 0.5rem;
  right: 0.7rem;
  left: 0.7rem;
  text-decoration: none;
  font-size: 0.8rem;

  &.alone {
    color: rgba(6, 6, 6, 0.66);
    position: relative;
    width: 100%;
    text-align: right;
    display: block;
    margin-right: 2rem;
    left: initial;
  }
}

.eyebrow {
  text-transform: uppercase;
  margin: 0;
  margin-top:1rem;
  font-weight: 700;
  opacity: 1;
  max-width: none;
  line-height: 0.4;
  display: inline-block;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid white;
    top: 0;
    width: 6rem;
  }
  &:before {
    right: 100%;
    margin-right: 15px;
  }
  &:after {
    left: 100%;
    margin-left: 15px;
  }

}

#simple-header {

     .description {

       display:none;
        @include bp(tablet-up) {
          margin-left: calc(50% - 15rem);
          display:block;
        }

        &.allDevices {
          display:block;
        }
    }

  ul {
    li {
      list-style: disc;
    }
  }

  &.left, &.right, &.tiny {
    .eyebrow {
      display:block;
      line-height: 1.5;
      &:before,
      &:after {
        width:0;
      }
    }
  }

  @include bp(tablet-down) {
    .eyebrow {
      display:block;
      line-height: 1;
      &:before,
      &:after {
        width:0;
      }
    }
  }

  &.image {
    p {
      text-shadow: 0px 0px 20px black;
    }
  }

  &.ts-hide {
    p {
      text-shadow: none;
    }
  }


  }


#simple-header.left {
  align-items: flex-start;

  ul {
    text-align: left;
    margin-left:1rem;
  }

  h1 {
    width: 100%;
    text-align: left;
  }
  p {
    width: 100%;
    text-align: left;
    padding-right: 3rem;
    font-weight: 500;
    max-width: 40rem;
    opacity: 1;

  }
  p.eyebrow  {
    padding-right:0;
  }
  .description {


    @include bp(tablet-up) {
      margin-left: 0;
    }
  }
  .header-ctas {

    & a:first-child {
      margin-left: 0;
    }
    & a:last-child {
      margin-right: 0;
    }
    width: 100%;
    justify-content: flex-start;

    a {
      margin-left: 0;
    }
  }

  @include bp(tablet-up) {

    .header-ctas {
      a {
        margin-left: 0.75rem;
      }
    }
  }
}

#simple-header.right {
  align-items: flex-end;
  h1 {
    width: 100%;
    text-align: right;
  }
  p {
    width: 100%;
    text-align: right;
    padding-left: 3rem;
    &.description {
      @include bp(tablet-up) {
        margin-left: calc(100% - 30rem)
      }
    }
  }

  .header-ctas {
    width: 100%;
    justify-content: flex-end;
  }
  .header-ctas:last-child {
    margin-right: 0;
  }

  .input-cta-container a:last-child {
    margin-right: 0;
  }

  @include bp(tablet-up) {
    h1 {
      margin-right: 2rem;
    }
    p {
      margin-right: 2rem;
    }
  }
}

#simple-header {
  &.alignCenter { align-items: center; }
  &.alignFlexStart { align-items: flex-start; }
  &.alignFlexEnd { align-items: flex-end; }
  &.alignStretch { align-items: stretch; }
  &.alignBaseline { align-items: baseline; }
}
