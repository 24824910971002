header {
  background-color: $pp-header-color;
}
.upper-right-cta {
  @extend %container;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100px;
  align-items: center;
  justify-content: space-between;
}
.logo-image {
  height: 100%;
  width: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  background-origin: content-box;
  margin-right: auto;
}
.header-tap-to-call {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  min-width: 200px;

  > * {
    font-family: $cta-primary-font;
    font-weight: 700;
    line-height: 1;
  }
  .cta-tagline {
    font-size: 16px;
  }
  .cta-number {
    font-size: 24px;

    @include bp(mobile-landscape-up) {
      font-size: 32px;
    }
    letter-spacing: -.08rem;
    color: #1d68b3;
  }
}
