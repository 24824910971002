.error-404 {
  display: flex;
  flex-direction: column;
  min-height: 550px;
  height: 45vh;
  margin: 0px auto;
  min-width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden ! important;
  overflow-y: hidden ! important;
  align-items: center;
  transition: all .5s;

  @include bp(tablet-up) {
    height: 75vh;
    min-height: 750px;
  }
  img {
    margin: 5rem auto 0rem;
  }
  .h3 {
    color: #000;
    font-size: 2rem;
    font-weight: 300;
  }
}
