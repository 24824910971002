*,
*:after,
*:before {
  @include box-sizing(border-box);
}


/**
 * 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent some browsers from
 *    letting these items shrink to smaller than their content's default
 *    minimum size. See http://bit.ly/1Mn35US for details.
 * 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
 */
.site {
  display: flex;
  flex-direction: column;
  height: 100%; /* 1, 3 */
}

.site-header,
.site-footer {
  flex: none; /* 2 */
}

.site-content {
  flex: 1 0 auto; /* 2 */
  padding: 14px 14px 0;
  width: 100%;
  margin: 0px auto;
  max-width: 1200px;
}

.site-content::after {
  content: '\00a0'; /* &nbsp; */
  display: block;
  margin-top: 14px;
  height: 0px;
  visibility: hidden;
}

@include breakpoint(768) {
  .site-content {
    padding-top: 56px;
  }

  .site-content::after {
    margin-top: 42px;
  }
}


.site-content--full {
  padding: 0;
}

.site-content--full::after {
  content: none;
}

$loan-type-wrapper: "LeadLeadInfoDemographics0Value";
$property-type-wrapper: "LeadRequestHomeRefinanceProperties0PropertyType";
$credit-rating-wrapper: "LeadApplicantInfoCreditSelfRating";
$property-value-wrapper: "LeadRequestHomeRefinanceProperties0PropertyValue";
$remaining-balance-wrapper: "LeadRequestHomeRefinanceExistingMortgages0RemainingBalance";
$interest-rate-wrapper: "LeadRequestHomeRefinanceExistingMortgages0InterestRate";
$street-address-input: "LeadContactInfoAddress";
$zip-code-input: "LeadContactInfoZipCode";
$city-input: "LeadContactInfoCity";
$state-select: "LeadContactInfoState";
$first-name-input: "LeadContactInfoFirstName";
$last-name-input: "LeadContactInfoLastName";
$email-input: "LeadContactInfoEmail";
$phone-input: "LeadContactInfoPhone";

$max-width: 60rem !default;

%flex {
  display: flex;
  margin: 3.5px auto 14px;
}

%inputLabel {
  text-align: left;
  text-transform: uppercase;
  color: rgba(0,0,0,.666);
  font-size: 14px;
  font-weight: 500;
}

@mixin container($id, $mobile-direction, $desktop-direction) {
  ##{$id} {
    @extend %flex;
    max-width: $max-width;
    flex-direction: $mobile-direction;

    @include breakpoint(768) {
      flex-direction: $desktop-direction;
      justify-content: space-around;
    }

    @content;
  }
}

@include container($loan-type-wrapper, column, row) {
  .form-prompt {
    font-size: 280px;
  }
};

@include container($property-type-wrapper, row, row) {
  flex-wrap: wrap;
};

@include container($credit-rating-wrapper, column, row);

@include container($property-value-wrapper, column, column);

@include container($remaining-balance-wrapper, column, column);

@include container($interest-rate-wrapper, column, column);

@include container($interest-rate-wrapper, column, column);

