.product-page {
  @import 'typography';
  @import 'base';
  @import 'grid';
  font-size: $base-font-size;
  @import 'header';
  @import 'sticky-header';
  @import 'section-group';
  @import 'tiles-group';
  @import 'testimonials';
}
