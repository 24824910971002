.circle-rating-svg {
  position: absolute;
  z-index: 2;
  height: 0.6667rem;
  width: 4.056rem;

  &__st0 {
    fill: #FFFFFF;
  }
  &__st1 {
    fill: #02B875;
  }
}
.circle-rating-shell {
  position: relative;
  overflow: hidden;
  height: 0.6667rem;
  width: 4.056rem;
  margin: 0.25rem 0;

  .circle-rating {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-primary;
    height: 0.6662rem;
  }
}
.star-rating-svg {
  position: absolute;
  z-index: 2;
  margin-right: -1px;
  g {
    fill: #FFFFFF;
  }
}
.star-rating-shell {
  position: relative;
  height: 30px;
  width: 100px;
  flex: 0 0 100px;
@include bp(tablet-up) {
  flex: 0 0 auto;
}
  .star-rating {
    position: absolute;
    top: 1px;
    max-width: 99px;
    left: 0px;
    background-color: $color-primary;
    height: 15px;
  }
}
.circle-rating-shell {
  margin-left: 3rem;
  margin-top: 0;
  margin-bottom: 0.8rem;
}
