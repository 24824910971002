// Colors
$jade: #02B875;
$spanish-gray: #9B9B9B;
$white-smoke: #f6f7f8;
$brilliant-azure: #39A0ED;

// Brand Colors
$margo: #02B875;
$color-primary: $margo;

// error Color
$color-error: #C62828;

$color-white: #FFFFFF;

$color-blue: rgb(34,86,123);

// 10 shades of gray
// https://material.google.com/style/color.html#color-color-palette
$gray-50: #FAFAFA;
$gray-100: #F5F5F5;
$gray-200: #EEEEEE;
$gray-300: #E0E0E0;
$gray-400: #BDBDBD;
$gray-500: #9E9E9E;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$fg-accent: $brilliant-azure;
$fg-bottom-bar-color: $spanish-gray;
$fg-link-hover-color: $jade;
$fg-background-color: $white-smoke;

$fg-bottom-bar-height: 50px;
$product-body-bg-color: #FFFFFF;
