.section-group {
  padding-bottom:1rem;
  .section-content {
    @extend %container;
  }
  h2.section-headline {
    text-align: center;
    max-width: 100%;
    background-color: $margo;
    color: $white-smoke;
    font-family: 'Source Sans Pro', 'Roboto', sans-serif;
    border-radius: 2px;
    padding: 16px;
    margin-bottom: 16px;
    font-weight: 200;
    margin-top: 0;
  }
  &.show-on-mobile {
    display:block;
    @include bp(tablet-up) {
      display:none;
    }
  }
  &.show-on-desktop {
    display:none;
    @include bp(tablet-up) {
      display:block;
    }
  }
}
