#summary-listings {
    .listing-shell {
        &:nth-child(even) {
            background: $gray-50;
            .star-rating-svg__st0 {
              fill: $gray-50;
            }
        }
    }

    .listing {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0 auto;
        max-width: 30rem;
        padding: 0.25rem 1rem 1rem;

        &__image {
            width: 8rem;
            height: 5rem;
            float: left;
            margin-right: 1rem;
            margin-bottom: 1rem;
            background-size: contain;
        }

        &__summary {
            display: inline;
            position: relative;
        }
        .star-ratings--reivews {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
        }

        .star-rating-shell {
          margin: 1rem 0;
        }
        &__title {
            border-bottom: 1px solid $divider-color;
            padding: 0.5rem 0;
            margin-bottom: 1rem;
            max-width: 100%;
            color: $gray-800;
            font-weight: 300;
        }
        @include bp(tablet-up) {
            max-width: 70rem;
            padding: 2rem 2rem 4rem 2rem;

            &__image {
                width: 12rem;
                height: 6rem;
                margin-right: 2rem;
                margin-bottom: 0;
            }

            &__summary {
                flex-grow: 2;
                margin-left: 1rem;
                max-width: 30rem;
            }

            &__cta {
                width: 16rem;
                margin-left: 2rem;
            }

            &__title {
              margin-bottom: 2rem;
            }

            &__content {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
