@include bp(tablet-down) {
  .featured, .not-featured {
    flex-direction: column;
    width: 100%;
    width: 100% !important;
  }
  .col-of-1, .col-of-2, .col-of-3, .col-of-4, .col-of-5, .col-of-6, .col-of-7, .col-of-8, .col-of-9, .col-of-10 {
    width: 100%;
  }
  .tile-group {
    flex-direction: column;
  }
}
@include bp(tablet-up) {
  .col-of- {
    &1 {
      width: 100%;
    }
    &2 {
      width: calc(100%/2);
    }
    &3 {
      width: calc(100%/3);
    }
    &4 {
      width: calc(100%/4);
    }
    &5 {
      width: calc(100%/5);
    }
    &6 {
      width: calc(100%/6);
    }
    &7 {
      width: calc(100%/7);
    }
    &8 {
      width: calc(100%/8);
    }
    &9 {
      width: calc(100%/9);
    }
    &10 {
      width: calc(100%/10);
    }
  }
}
