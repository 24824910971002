.compare-listings-ff {
  .compare-listings-main {
    width: 100%;
  }
  &.flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include bp(tablet-up) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .widgets {
    max-width: 300px;
    min-width: 300px;
    margin-top: 1.5rem;
    margin-right: auto;
    margin-left: auto;

    @include bp(tablet-up) {
      margin-right: 2rem;
      margin-left: 1rem;
    }
    iframe {
      margin-bottom: 1rem;
      display: none;

      @include bp(tablet-up) {
        display: block;
      }
      &.mobile-true {
        display: block;
      }
    }
  }
  .counter-container {
    h2 {
      font-size: 1.2rem;
      padding-top: 1rem;
      width: 100%;
      max-width: 100%;
      text-align: center;
      font-family: Raleway;
    }
    p {
      font-size: 1.1rem;
      width: 100%;
      text-align: center;
      max-width: 100%;
      color: $margo;
    }
    @include bp(tablet-up) {
      h2 {
        font-size: 1.5rem;
      }
      p {
        font-size: 1.3rem;
      }
    }
  }
  padding: 0 1rem;

  .listing__link {
    margin: 0 auto;
      line-height: 54px;
  }
  header {
    background-color: $color-blue;

    h1 {
      font-weight: 400;
      max-width: 100%;
      text-transform: none;
    }
  }
  .full-profile-link {
    text-align: center;
    display: block;
  }
  .listing {
    .view-more {
      display: flex;
      border-top: 1px solid $gray-100;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      color: $gray-700;
      height: 49px;
      justify-content: center;
      margin-top: auto;
      i {
        padding-right: 0.2rem;
      }
      &.mobile-only {
        padding-top: 10px;
      }
    }
    border: 1px solid $gray-200;
    border-radius: 0.2rem;
    margin: 2rem auto;
    overflow: hidden;
    background-color: #FFFFFF;

    &__name {
      padding: 0;
      margin-top: 1rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 1.5rem;
      color: $gray-800;
    }
    &__title {
      display: flex;
      align-items: center;
      padding-top: .5rem;
      justify-content: space-around;

      @include bp(tablet-up) {
        flex-direction: column;
      }
      .listing__image {
        flex: 0 1 200px;
        @include bp(tablet-up) {
          flex: 0 1 auto;
        }
      }
      .view-more {
        display: none;
        @include bp(tablet-up) {
          display: flex;
          padding-top: 1rem;
        }
      }
      margin: 0 auto;
    }
    &__eyebrow {
      font-size: 0.95rem;
      color: $gray-500;
      margin-bottom: 0.4rem;

      @include bp(tablet-up) {
        margin-bottom: 0.7rem;
      }
    }
    &__image {
      height: 5rem;
      width: 10rem;
      margin-bottom: 0.5rem;
    }
    &__short {
      display: flex;
      flex-direction: column;

      @include bp(tablet-up) {
        flex-direction: row;
      }
      .listing__metrics {
        display: flex;
        padding-top: 1rem;
        justify-content: space-around;
        margin: auto;
        width: 100%;
      }
      .listing__rating {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        justify-content: flex-start;
        flex-basis: 100%;
        padding-left: 1rem;
        margin-right: 1rem;

        &.pros, &.cons {
          .listing__rating--name {
            font-size: 1rem;
            font-weight: 400;
            text-transform: none;
          }
          ul {
            margin-top: 8px;
            li {
              display: flex;
              margin-left: 8px;
              line-height: 1.2;
              padding-bottom: 10px;
              align-items: flex-start;
              i {
                flex: 0 0 24px;
                display: inline-flex;
                align-items: flex-start;
                line-height: .9;
                min-width: 0;
                min-height: 0;
                font-size: 20px
              }
            }
          }
        }
        i.pro {
          color: #13BB70;
        }
        i.con {
          color: #D2040A;
          transform: rotate(180deg);
          margin-right: 6px;
        }
        &:nth-child(4) {
          display: none;

          @include bp(tablet-up) {
            display: flex;
          }
        }
        .listing__rating--name {
          text-transform: uppercase;
          font-size: 0.7rem;
          margin-bottom: 0;
        }
        .listing__rating--value {
          font-size: 0.9rem;
          margin-bottom: 0;
        }
        &.badges {
          > div {
            display: flex;
            align-items: center;
            color: $margo;
          }
          i {
            padding-right: 0.25rem;
          }
          .p {
            font-weight: 600;
          }
        }
        &.stars {
          width: 4rem;
        }
      }
    }
    .listing__info {
      min-width: 10rem;
      position: relative;
      display: block;

      @include bp(tablet-up) {
        padding-left: 1rem;
        min-width: 12rem;
        margin-right: 1rem;
      }
    }
    .listing__middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;

      @include bp(tablet-up) {
        flex-direction: row;
      }
    }
    .listing__summary {
      flex: 1;
      border-right: 1px solid $gray-200;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      position: relative;

      > * {
        flex: 0 0 100%;
        overflow: hidden;
      }
      .p {
        display: none;
        margin-bottom: 0;
        padding: 1rem 1rem 0;
        font-size: 0.8rem;
        overflow: scroll;

        @include bp(tablet-up) {
          display: block;
          height: 6.5rem;
          position: relative;
        }
      }
      @include bp(tablet-up) {
        &:after {
          position: absolute;
          bottom: 3rem;
          left: 0;
          height: 3rem;
          width: 100%;
          content: '';                  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
          background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);                  /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);                  /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);                  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);                  /* IE6-9 */
        }
      }
    }
    .listing__compareCTA {
      display: flex;
      margin-bottom: 8px;
      @include bp(tablet-up) {
        min-width: 8rem;
        flex-wrap: wrap;
        margin-bottom: 0;
      }
      a {
        flex: 0 1 47%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 2.2223rem;
        text-align: center;
        font-weight: 700;
        text-decoration: none;
        @include bp(tablet-up) {
          flex: 1 1 100%;
        }
        &.compare-text {
          background-color: $gray-100;
          color: $gray-700;
        }
        &.compare-button {
          background-color: $margo;
          color: #FFFFFF !important;
          border-radius: 0 !important;

          span {
            color: white;
          }
        }
        &.compare-altButton {
          border-radius: 0 !important;
        }
      }
    }
  }
  @include bp(tablet-up) {
  }
}
div[data-expand-target] {
  display: none;
}
.c-listing-expanded {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ECECEC;
  justify-content: space-between;

  @include bp(tablet-up) {
    flex-wrap: no-wrap;
  }
  &__metric.mobile {
    display: flex;
    flex-wrap: wrap;

    > p {
      flex: 1 1 100%;
    }
  }
  .listing__mobile-review {
    display: flex;
    flex: 1 0 9.3333rem;
    margin-bottom: 5%;

    p.p {
      font-size: 0.75rem;
    }
    &--image {
      width: 75px;
      background-size: contain !important;
    }
    &--metrics {
      p {
        margin: 0;
      }
    }
    &--app-rating {
      display: flex;
      overflow: visible;
      position: relative;
      background-clip: content-box;
      background: $margo;
      height: 1.1112rem;
      margin-bottom: 0.25rem;

      svg {
        height: 20px;
        position: relative;
      }
    }
  }
  &__fullReview {
    flex-basis: 100%;
    padding: 0 1rem 0.5rem;
    order: 2;

    @include bp(tablet-up) {
      order: 0;
      flex-basis: 55%;
      padding: 0 1rem 1rem;
    }
    a, p {
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }
    > a {
      @include bp(tablet-up) {
        display: none;
      }
    }
  }
  &__profile {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem 0.5rem;
    border-left: 1px solid $gray-100;
    max-width: 22rem;

    > div {
      flex: 1 1 100%;
    }
  }
  &__metric--header {
    font-size: 1rem;
    border-bottom: 1px solid $gray-200;
    width: 100%;
    display: block;
    font-weight: 300;
    margin: 1rem 0;
  }
  &__metric--header:only-child {
    display: none;
  }
  & .listing_mobile-review {
    border: 1px solid red;
  }
  &__rating {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7rem;
    width: 25%;

    &--name {
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0;
    }
    &--value {
      font-size: 0.75rem;
      margin: 0;
    }
  }
  &__linkGroups {
    flex: 1 1 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > a {
      font-size: 0.75rem;
      margin: 0 auto;
    }
  }
}
.desktop-only {
  @include bp(tablet-down) {
    display: none !important;
  }
}
.mobile-only {
  @include bp(tablet-up) {
    display: none !important;
  }
}
