// Typography
// ---------------
// Sets typography for all of document
// ===============

@import 'https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic,thin,light,bolditalic,black,medium&amp;lang=en';

@import 'https://fonts.googleapis.com/css?family=Roboto+Slab:400|Roboto:300|400|500|700';

@import 'https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700';

@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,200,300,400,600,700,900';

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@import 'https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.css';

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* Base Font Sizes  */
$font-size: 18px;

$divider-color: $gray-300;
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src:url('//fonts.gstatic.com/s/materialicons/v21/2fcrYFNaTjcS6g4U3t-Y5UEw0lE80llgEseQY3FEmqw.woff2') format('woff2');
}

