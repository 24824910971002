.listing__decoration {
  display: flex;
  width: 95%;
  padding: 8px 0px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @include bp(tablet-up) {
    justify-content: flex-start;
    align-self: stretch;
    align-items: flex-end;
    flex-direction: column;
    flex: 0 0 135px;
    order: 2;
  }
  .listing-availability {
    display: flex;
    margin-top: auto;
    margin-right: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: .75rem;
    line-height: 1.2;
    text-align: left;
    align-items: center;
    flex: 0 1 50%;
    justify-content: center;

    > i {
      height: 34px;
      font-size: 34px;
      color: #F6A623;
    }
    @include bp(tablet-up) {
      flex: 0 0 auto;
    }
  }
  > .decorative-image {
    position: absolute;
    top: -67%;
    right: 2%;
    height: 100%;
    width: 22%;
    max-width: 85px;

    @include bp(tablet-up) {
      position: relative;
      top: auto;
      right: auto;
      height: 100px;
      width: 100px;
      align-self: center;
    }
    // preceded by decorative-image
    + .listing-availability {
      flex: 1;

      @include bp(tablet-up) {
        flex: 0 0 auto;
      }
    }
  }
  > .listing-ribbon {
    position: relative;
    display: inline-block;
    z-index: 10;
    margin: 0 auto;
    padding: .25em .5em;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    letter-spacing: -0.1px;
    text-align: center;
    align-items: center;
    justify-content: center;
    @include bp(tablet-up) {
      flex: 0 1 auto;
      margin: 0;
    }
    &:before, &:after {
      content: "";
      position: absolute;
      display: block;
      bottom: -.5em;
      z-index: 5;
      width: .2em;
      border: .9em solid cyan;
    }
    &:before {
      left: -1.25em;
      border-right-width: .75em;
      border-left-color: transparent !important;
    }
    &:after {
      right: -1.25em;
      border-left-width: .75em;
      border-right-color: transparent !important;

      @include bp(tablet-up) {
        display: none;
      }
    }

    > span.ribbon-content {
      font-size: .75rem;
      font-weight: 600;
      line-height: 1.9;
      white-space: nowrap;

      &:before, &:after {
        content: "";
        position: absolute;
        display: block;
        bottom: -9px;
        z-index: 6;
        border-style: solid;
        border-color: rgba(0, 0, 0, .5) transparent transparent transparent;
      }
      &:before {
        left: -1px;
        border-width: .65em 0 0 .65em;
      }
      &:after {
        right: -1px;
        border-width: .65em .65em 0 0;

        @include bp(tablet-up) {
          display: none;
        }
      }
    }
  }
}
