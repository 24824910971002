.listing__link {
    text-decoration: none;
    display: block;
    text-align: center;
    opacity: 1;
    text-decoration: underline;
    max-width: 20rem;
    margin: 1rem auto;

    &.button {
        color: $color-white;
        background: $color-primary;
        border: 0.5556rem solid $margo;
        border-radius: 100px;
        font-size: 1.25rem;
        text-decoration: none;
        width: 90%;
        line-height: 3rem;
    }

    &.altButton {
            border: 0.15556rem solid $margo;
            border-radius: 100px;
            font-size: 1.25rem;
            text-decoration: none;
            width: 90%;
    }
}

.cta.button {
    color: $color-white;
    border: 0.15rem solid white;
    border-radius: 100px;
    font-size: 1.25rem;
    line-height: 1;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    z-index: 1;


}
