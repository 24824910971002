#static-content {
  max-width: 980px;
  margin: 0px auto;

  header {
    text-align: center;
    padding-top: 2.1666rem;
    padding-bottom: 2.1666rem;

    @include bp(tablet-up) {
      padding-top: 4.1666rem;
      padding-bottom: 4.1666rem;
    }
    h1 {
      color: $margo;
      text-transform: none;
      margin: 0;
    }
    p.eyebrow {
      text-transform: none;
      color: $gray-600;
      max-width: 100%;
      font-size: 1rem;
    }
    p:empty {
      display: none;
    }
  }
  .section-content {
    padding-bottom: 2rem;

    h2 {
      text-align: center;
      margin: 0 auto 2rem;
      max-width: 85%;
    }
    p + h2 {
      margin-top: 3rem;
    }
    p {
      margin: 16px auto 27px;
      max-width: 92.625%;
    }
    p + ul, p + ol {
      margin-top: -1rem;
      color: #303030;
    }
  }
  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  ul {
    list-style-type: disc;
    margin-left: 4rem;
  }
  ol {
    list-style-type: decimal;
    margin-left: 4rem;
  }
  ol ul, ul ul {
    list-style-type: disc;
    margin-left: 2rem;
  }
  ol ol, ul ol {
    list-style-type: lower-latin;
    list-style-position: outside;
    margin-left: 2rem;
  }
  li {
    list-style: inherit;
    margin-bottom: .5rem;
    font-size: .95rem;
  }
}
