body.widget {
  margin: 0;
  padding: 0;
  overflow: hidden;

  .right-rail-cta-container {
    height:100%;
    width: 100%;
  }
}

#refi-calculator {
  border: 1px solid #CCC;
  background: rgba(128, 128, 128, 0.05);
  margin-top:10px;
  padding-bottom:1rem;

  hr {
    background-color: #CCC;
    height: 1px;
    border: 0;
  }
  h2 {
    font-size: 1.5rem;
    padding-left: 0.5rem;
    border-bottom: 1px solid #CCC;
    padding-bottom: 0.25rem;
  }
  .total-savings {
    font-family: Raleway;
    border-top: 1px solid #CCC;
    margin-top: 0.5rem;
    padding: 1rem;
    text-align: center;

    #savings {
      color: $margo;
      font-size: 2rem;
      margin-bottom:1.5rem;
    }
  }
  .refi-field {
    font-family: Raleway;
    font-size: 0.8rem;
    text-transform: UPPERCASE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5rem;

    input {
      padding: 0.5rem;
      border: 1px solid #DDD;
      width: 5rem;
      text-align: right;
    }

  }

}
