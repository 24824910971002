#LeadRequestHomeRefinanceProperties0PropertyType {
  .overlay:after {
    content: '';
  }
  justify-content: space-between;
  flex-wrap: wrap;
  label.radio-label {
    flex: 1 0 calc(50% - 70px);
    margin: 3.5px 14px;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    cursor: pointer;

    @include breakpoint(768) {
      margin: 14px;
      flex: 1 1 100px;
    }
    &:hover .radio-image, input:checked + .radio-image {
      background-size: contain;
    }
  }

  .overlay {
    order: -1;
    font-weight: 600;
    font-size: 16.1px;
    margin-bottom: 14px;
  }

  div.radio-image {
    min-height: 100px;
    background-size: contain;
    @include transition(all .25s);
    @include breakpoint(768) {
      min-height: 150px;
    }
  }
}

#SingleFamilyHome {
  .radio-image {
    background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/primary-bw.jpg") no-repeat center;
  }

  &:hover .radio-image, input:checked + .radio-image {
  background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/primary-color.jpg") no-repeat center;
  }
}

#MultiFamilyHome {
  .radio-image {
    background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/multi-bw.jpg") no-repeat center;
  }

  &:hover .radio-image, input:checked + .radio-image {
    background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/multi-color.jpg") no-repeat center;
  }
}

#Condominium {
  .radio-image {
    background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/condo-bw.jpg") no-repeat center;
  }

  &:hover .radio-image, input:checked + .radio-image {
    background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/condo-color.jpg") no-repeat center;
  }
}

#MobileHome {
  .radio-image {
    background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/mobile-bw.jpg") no-repeat center;
  }

  &:hover .radio-image, input:checked + .radio-image {
    background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/mobile-color.jpg") no-repeat center;
  }
}

// iOS Safari sticky :hover fix for > iPhone 6
@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  #SingleFamilyHome {
    &:hover .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/primary-bw.jpg") no-repeat center;
    }

    input:checked + .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/primary-color.jpg") no-repeat center;
    }
  }

  #MultiFamilyHome {
    &:hover .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/multi-bw.jpg") no-repeat center;
    }

    input:checked + .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/multi-color.jpg") no-repeat center;
    }
  }

  #Condominium {
    &:hover .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/condo-bw.jpg") no-repeat center;
    }

    input:checked + .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/condo-color.jpg") no-repeat center;
    }
  }

  #MobileHome {
    &:hover .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/mobile-bw.jpg") no-repeat center;
    }

    input:checked + .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/mobile-color.jpg") no-repeat center;
    }
  }
}

// iOS Safari sticky :hover fix for <= iPhone 6
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  #SingleFamilyHome {
    &:hover .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/primary-bw.jpg") no-repeat center;
    }

    input:checked + .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/primary-color.jpg") no-repeat center;
    }
  }

  #MultiFamilyHome {
    &:hover .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/multi-bw.jpg") no-repeat center;
    }

    input:checked + .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/multi-color.jpg") no-repeat center;
    }
  }

  #Condominium {
    &:hover .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/condo-bw.jpg") no-repeat center;
    }

    input:checked + .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/condo-color.jpg") no-repeat center;
    }
  }

  #MobileHome {
    &:hover .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/mobile-bw.jpg") no-repeat center;
    }

    input:checked + .radio-image {
      background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/mobile-color.jpg") no-repeat center;
    }
  }
}
