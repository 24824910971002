#listing-profile {
  display: flex;
  padding: 0rem 1rem;
  flex-direction: column;
  max-width: 53.3rem;
  margin: 0 auto;

  @include bp(tablet-up) {
    justify-content: space-between;
    flex-direction: row;
    padding: 1rem;
  }
  .listing-profile__left {
    flex-direction: column-reverse;
    display: flex;

    @include bp(tablet-up) {
      width: 48%;
      flex-direction: column;
    }
  }
  .listing-profile__fullSummary {
    h3 { color: $margo; }
  }
  .listing-profile__right {
    display: flex;
    flex-direction: column-reverse;

    @include bp(tablet-up) {
      flex-direction: column;
      width: 45%;
    }
  }
  .content-header {
    font-size: 1.365rem;
    border-bottom: 1px solid $divider-color;
    width: 100%;
    display: block;
    font-weight: 300;
    margin: 1rem 0rem;
  }
  .content-header:only-child {
    display:none;
  }
  .listing__linkGroups {
    text-align: center;

    > a {
      display: table;
      margin: 1rem auto;
    }
  }
  &__metric--header {
    font-size: 1rem;
    border-bottom: 1px solid $divider-color;
    width: 100%;
    display: block;
    font-weight: 300;
    margin: 1rem 0rem;
  }
  &__rating {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;

    &--name {
      font-size: .75rem;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0;
    }
    &--value {
      font-size: .75rem;
      margin: 0;
    }
  }
}
