body.refi-slider {
    background-color: #FFF;

    form {
        @import "utils/variables";
        @import "utils/mixins";
        @import "base/typography";
        @import "layout/structure";
        @import "layout/content";
        @import "components/shortIcon";
        @import "components/propertyType";
        @import "components/buttons";
        @import "components/creditSlider";
        @import "components/slider";
        @import "components/input";
        @import "components/tcpa";
        @import "components/eoFeed";
        @import "components/eo";
        @import "shame";
    }
}
