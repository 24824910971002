.mailto-form {
  font-family: 'Source Sans Pro', 'Open Sans', Roboto, sans-serif;

  &__field {
    margin-bottom: .75rem;
    width: 100%;

    label {
      font-size: .925rem;
      display: block;
      margin-bottom: .25rem;
    }
    input, select, textarea {
      align-items: center;
      border: none;
      border-radius: 3px;
      box-shadow: none;
      -webkit-appearance: none;
      display: inline-flex;
      font-size: 1rem;
      justify-content: flex-start;
      line-height: 1.5;
      -moz-appearance: none;
      padding-bottom: 0.375em;
      padding-left: 0.625em;
      padding-right: 0.625em;
      padding-top: 0.375em;
      position: relative;
      vertical-align: top;
      background-color: white;
      border: 1px solid #dbdbdb;
      color: #363636;
      box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
      max-width: 100%;
      outline: none;
      width: 100%;
      transition: all 86ms ease-out;

      &:hover {
        border-color: #b5b5b5;
      }
      &:active, &:focus {
        border-color: #009BFF;
      }
    }
    .custom-select {
      display: inline-block;
      height: 2.25em;
      position: relative;
      vertical-align: top;

      &::after {
        border: 1px solid #009BFF;
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: 0.5em;
        pointer-events: none;
        position: absolute;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 0.5em;
        margin-top: -0.375em;
        right: 1.125em;
        top: 50%;
        z-index: 4;
      }
    }
    select {
      cursor: pointer;
      padding-right: 2.5rem;
      -moz-appearance: none;

      &:invalid {
        color: #a9a9a9;
      }
    }
    input, select {
      height: 2.25em;
    }
  }
  button {
    display: inline-block;
    padding: .5rem 1rem;
    background-color: #009BFF;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    border-radius: 4px;
    color: #FFFFFF;
    cursor: pointer;
  }

  button.nostyle {
        background-color: transparent;
        padding-left:0;
        padding-top:20px;
        margin: 0 auto;
        display:block;
        height:4rem;
  }
}
