div.rangeValueWrapper {
  font-size: 21px;
  margin: 14px;
  font-weight: 200;
}
input[type=range] {
  -webkit-appearance: none;
  cursor: pointer;
  margin: 25px auto;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    -moz-border-radius: 3px;
    -moz-border-top-left-radius: 3px;
    -moz-border-top-right-radius: 3px;
    -moz-border-bottom-left-radius: 3px;
    -moz-border-bottom-right-radius: 3px;
    -webkit-border-radius: 3px;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
  }
  &:focus::-webkit-slider-runnable-track {
    background: rgba(0, 0, 0, 0.3);
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0;
    background-color: #24b6fe;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 24px;
    margin-top: -16px;
  }
  &:focus {
    outline: 0;
  }
  & {
    border: 1px solid #fff;
    width: 100%;
  }
  &::-moz-range-track {
    width: 100%;
    height: 5px;
    background: rgba(0, 0, 0, 0.3);
    border: 0;
    border-radius: 3px;
  }
  &::-moz-range-thumb {
    border: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #000;
  }
  &:-moz-focusring {
    outline: 1px solid #fff;
    outline-offset: -1px;
  }
  &:focus::-moz-range-track {
    background: rgba(0, 0, 0, 0.3);
  }
  &::-ms-track {
    width: 100%;
    height: 5px;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
  }
  &::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }
  &::-ms-thumb {
    border: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #000;
  }
  &:focus::-ms-fill-lower {
    background: #888;
  }
  &:focus::-ms-fill-upper {
    background: #ccc;
  }
}
