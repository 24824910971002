@mixin core-cta($border-color, $icon-url) {
  .c-secondary-nav__image {
    background: url($icon-url) no-repeat center center !important;
    background-size: contain !important;
    display: block;
  }
}
// * {
//   opacity: 1;
// }

#l-gateway {
  padding: 1rem;
}
