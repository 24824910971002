.factoid-group {
  width: 100%;
  position: relative;
  padding: 1rem 0rem;
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__title {
    @extend .p;
    display: block;
    margin-bottom: 0;
    border-bottom: 1px solid $divider-color;
    font-weight: 300;
    .c-listing-expanded__ratingGroups & {
      border-bottom: 1px solid $gray-200;
    }
  }
  &__title:only-child {
    display:none;
  }
  &__mobile-review {
    display: inline-flex;
    width: 45%;
    margin-top: 1rem;

    &--image {
      width: 54px;
      height: 54px;
      margin-right: .55rem;
      border-radius:1rem;
    }
    .reviews {
      font-size: .75rem;
      margin: 0;
    }
  }
  &__metric {
    flex: 1 1 100%;
    display: flex;
    justify-content: space-between;
    margin: .75rem auto;
    &-name {
      @extend .p;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 0;
      font-size: .75rem;
    }
    &-value {
      @extend .p;
      margin-bottom: 0;
      font-size: .75rem;
    }
  }
}
