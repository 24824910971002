.c-main-nav-shell {
    z-index: 100;
    position: absolute;
    height: $nav-height;
    width: 100%;
    max-width: $site-max-width;
    top: 0;
    left:0;
  //  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

≈

    .advertiser-disclosure {
      color: white;
      text-decoration: none;
      margin: 0.5rem;
      font-size: 0.8rem;
      line-height: 2rem;
    }
    .c-main-nav {
        position: relative;
        display: flex;
        align-items: center;
        height: inherit;
        padding: 0;
        width: 100%;
        background: $color-white;
        @include bp(tablet-up) {
          justify-content: space-between;
        }


        &__logo_container {
          margin-left: .5rem;
          @include bp(tablet-up) {
            display: flex;
            justify-content: flex-start;
            max-width: 10rem;
            flex-direction: column;
            min-width:10rem;
          }

          .motto {
            font-weight: 100;
            font-size: 0.8rem;
            font-style: italic;
            padding: 0;
            margin: 0;
            color: #888;
            width:13rem;
            min-width:13rem;
            @include bp(tablet-up) {
              display:block;
            }
          }
        }

        &__logo {
            display:block;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 1rem;
            .o-brand-logo{
              padding-top: 0.09rem;
            }
            @include bp(tablet-up) {
                width: 7rem;
            }
        }

        &__menu {
            display: none;
            @include bp(tablet-up) {
                padding-right: 2rem;
                position: relative;
                z-index: inherit;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                width: 100%;
                max-width: 44rem;
                text-transform: lowercase;

            }

        }

        &__menu.size-1, &__menu.size-2 {
          padding-right: 2rem;
          position: relative;
          z-index: inherit;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          max-width: 44rem;
          text-transform: lowercase;
        }

        &__cta {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: inherit;
            width: 5rem;
            width: 100%;
            max-width: 8rem;
            @include bp(tablet-up) {
                min-width: 7rem;
                width: 100%;
                max-width: 10rem;

            }
        }
    }

    .o-menu-link-shell {
        height: $nav-height;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: inherit;
        text-decoration: none;
        line-height: 1;
        color: $gray-700;
        flex-direction: column;
        .o-menu-link {
          padding: 0 0.5rem;
        }
        .o-menu-link--cta {
            color: $color-primary;
            font-weight: 700;
            font-size: 0.7rem;
            height: 0.7rem;
            @include bp(tablet-up) {
                font-size: 0.8rem;
                height: 0.8rem;
            }
        }

        &.active-page {
            &:before {
              width: 100%;
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              height: 0.15rem;
              background: $color-primary;
            }
            &:after {
              position: absolute;
              bottom: -0.6rem;
              height: 0.6rem;
              width: 0.6rem;
              content: ' ';
              opacity: 1;
              z-index: -2;
              background: transparent;
              left: calc(50% - 0.6rem);
              border-left: 0.6rem solid transparent;
              border-right: 0.6rem solid transparent;
              border-top: 0.6rem solid $color-white;
            }
        }

    }
    @include bp(tablet-up) {
      &:hover {
          position: fixed;
      }
    }
}
