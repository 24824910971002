.c-secondary-nav-shell {
    background: transparent;
    @include bp(tablet-up) {
        position: fixed;
        z-index: -1;
        left: 0;
        right: 0;
        top: -34rem;
        width: 100%;
        transition: all 0.25s;
        &.active {
            top: $nav-height;
        }
    }

    &.vertical-lander {
      position: inherit;

    }
}
.vertical-lander {
  .c-secondary-nav {
    box-shadow: none;
    background-color: transparent;
    border-top: none;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include bp(tablet-up) {
      padding: 3rem;
    }
  }
}
.c-secondary-nav {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    background-color: $gray-50;
    border-top: 0.0556rem solid $gray-200;
    z-index: inherit;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
    text-align: center;

    padding: 1rem;
    left: 0;
    width: 100%;
    max-width: $site-max-width;

    &__cta-container {
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &__cta {
        width: 50%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        transition: all 2.25s;

        &:hover .h3{
          color: $color-primary;
        }

        @include bp(tablet-up) {
          width: 25%;
        }
    }

    &__footer {
        background: $gray-100;
        border-top: 0.0556rem solid $gray-200;
        text-align: center;
        padding: 0.5rem 0;
        text-decoration: none;
        max-width: $site-max-width;
        margin: auto;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        .o-nav-link {
          font-weight: 700;
          color: $color-primary;
        }
    }

    &__image {
        height: 8rem;
        width: 8rem;
        background-size: contain;
        > div {
            height: 100%;
        }
    }
}

.o-menu-link-shell:hover {
    .c-secondary-nav-shell {
        @include bp(tablet-up) {
            top: $nav-height;
        }
    }
}

  .vertical-lander .c-secondary-nav {
    p.p { display: none; }
}
