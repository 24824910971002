$jade: #02B875;
$spanish-gray: #9B9B9B;
$white-smoke: #f6f7f8;
$brilliant-azure: #39A0ED;


$fg-accent: $brilliant-azure;
$fg-bottom-bar-color: $spanish-gray;
$fg-link-hover-color: $jade;
$fg-background-color: $white-smoke;

$fg-bottom-bar-height: 50px;

#site-container.flat-gray {
  @import 'base';
  @import 'svgs';
  @import 'typography';
  @import 'under-nav-cards';
}
