blockquote {
    background: rgba(211, 211, 211, 0.28);
    padding: 1rem;
    padding-bottom: 0.2rem;
    border-left: 2px solid $margo;
    margin-bottom: 1rem;
}
#404-block {
    display: none;
}
#404-block::first-of-type {
    display: block;
}
// overrides for internal iframed pages
body.iframed {
    #off-canvas, #c-main-nav, #c-footer {
        display: none;
    }
    #main-content {
        padding-top: 0;
        border: none;
    }
}
#loading-indicator {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 15rem);
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-animation: spin 1s infinite;
    animation: spin 1s infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    color: #266990;
}
#loading-indicator:before {
    content: '\f29C';
    font-family: "Ionicons";
    font-size: 36px;
    line-height: 1;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
#loading-indicator.ng-scope {
    display: none;
}
code, pre {
    font-size: small;
    max-width: 100%;
    overflow: hidden;
}
.tiles-group {
    .featured {
        background: $margo;

        .title {
            height: 2.0rem;
            text-align: center;
            font-size: 1rem;
            color: white;
            font-weight: 800;
            font-family: Raleway;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
    }
    .not-featured {
        .title-spacer {
            display: none;
            height: 2.5rem;

            @include bp(mobile-landscape-up) {
                display: block;
            }
        }
    }
}

body {
    color: #4D4D4D;
}
