@mixin bp($point) {
    @if $point == small-mobile-up {
        @media all and (min-width:$breakpoint--small-mobile) {
            @content;
        }
    }
    @else if $point == mobile-up {
        @media all and (min-width:$breakpoint--mobile) {
            @content;
        }
    }
    @else if $point == large-mobile-up {
        @media all and (min-width:$breakpoint--large-mobile) {
            @content;
        }
    }
    @else if $point == mobile-landscape-down {
        @media all and (max-width:$breakpoint--mobile-landscape) {
            @content;
        }
    }
    @else if $point == mobile-landscape-up {
        @media all and (min-width:$breakpoint--mobile-landscape) {
            @content;
        }
    }
    @else if $point == tablet-up {
        @media all and (min-width:$breakpoint--tablet) {
            @content;
        }
    }
    @else if $point == tablet-down {
        @media all and (max-width:$breakpoint--tablet) {
            @content;
        }
    }
    @else if $point == tablet-landscape-up {
        @media all and (min-width:$breakpoint--tablet-landscape) {
            @content;
        }
    }
    @else if $point == desktop-up {
        @media all and (min-width:$breakpoint--desktop) {
            @content;
        }
    }
    @else if $point == $point {
        @media all and (min-width:$point) {
            @content;
        }
    }
}
