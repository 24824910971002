$headline-default: rgba(0, 0, 0, 0.666);
$headline-accent: #24b6fe;

.site-content {
  color: $primary-font-color;
}
form, address {
  text-align: center;
}

.content-one, .content-two, .content-three, .content-four, .content-five {
  text-align: left;
  max-width: 700px;
  margin: 0px auto;
  img {
    margin: 28px auto;
  }
}
.form-prompt {
  font-size: 19.11px;
  margin: 25px auto 21px;
}

.page[data-page='/lander'] {
  .form-prompt {
    font-size: 22.75px;
    margin-bottom: 0;
    @include breakpoint(768) {
      font-size: 28px;
    }
  }
}

.formly-field-TCPA {
  font-size: 10.5px;
}

h1 {
  margin: 0;
  padding: 0;
  color: $headline-accent;
  font-size: 32.62px;
  text-align: center;
  margin-bottom: 28px;
  @include breakpoint(768) {
    font-size: 39.2px;
  }
}

h1, h2, h3, h4 {
  width: 100%;
  font-weight: 300;
  line-height: 18.62px;
}
 h2, h3, h4 {
  color: $headline-default;
  text-align: left;
 }

h2 {
  font-size: 18.2px;
  line-height: 18.2px;
  margin-bottom: 20px;
  font-weight: 300;
  text-align: center;
  margin: 3% 0;
}

a {
  cursor: pointer;
}

ol {
  padding-left: 14px;
  ~ p {
    padding-left: 28px;
  }
   ~ ul {
    padding-left: 70px;
   }
}

.form-prompt {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  @include bp(tablet-up) {
    font-size: 28px;
  }
}
