& {
  background: white;
  font-family: 'Lato', sans-serif;
  line-height: 1.35;
  text-rendering: optimizeLegibility;
  text-align: center;
}

.page[data-page='/lander'] .btn.next {
    width: 100%;
    max-width: 400px;
    font-weight: 600;
}

.btn {
    box-sizing: border-box;
    font-weight: 300;
    outline: 0;
    color: #fff;
    font-size: 21px;
    /*line-height: 46.2;*/
    background: #ff8901;
    /*margin: 38.5px auto 28px;*/
    width: 100%;
    max-width: 560px;
    display: block;
    border-radius: 2px;
    background-clip: padding-box;
    transition: box-shadow 0.25s, background 0.25s, -webkit-transform 0.25s;
    transition: box-shadow 0.25s, background 0.25s, transform 0.25s;
    transition: box-shadow 0.25s, background 0.25s, transform 0.25s, -webkit-transform 0.25s;
}

.btn.back {
    background: #fff;
    color: rgba(102, 102, 102, 0.666);
    font-size: 14px;
    border: 1px solid;
    line-height: 14px;
    padding: 7px 21px;
    width: 115px;
    margin-top: 14px;
}

.btn:hover {
    box-shadow: 0 14px 21px -7px rgba(0, 0, 0, 0.3);
}

& {
  overflow: hidden;
}

.formly-field-group {
  position: relative;
  left: 0;
  z-index: 1;
  bottom: 0;
  width: 100%;
  max-width: 960px;
  margin: auto;
  height: 100%;
  float: none;
}

.formly-field-group.ng-enter-prepare {
  opacity: 0;
}

.formly-field-group.ng-enter, .formly-field-group.ng-enter-active {
  -webkit-transition: all 0.2s, -webkit-transform 0.2s;
  -moz-transition: all 0.2s, -moz-transform 0.2s;
  -ms-transition: all 0.2s, -ms-transform 0.2s;
  -o-transition: all 0.2s, -o-transform 0.2s;
  transition: all 0.2s, transform 0.2s;
}

.formly-field-group.ng-enter {
  left: 103%;
  opacity: 0;
}

.formly-field-group.ng-enter-active {
  left: 0;
  opacity: 1;
}

.formly-field-group.ng-leave {
  left: -103%;
  height: 0px;
  opacity: 0;
}

.formly-field-group.ng-leave-active {
  left: -103%;
  height: 0px;
  opacity: 0;
}

.ng-animate:not(:input) {
  opacity: 0;
  display: none;
  height:0px;
}