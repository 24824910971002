$max-width: 560px;
$error: rgb(214, 91, 89);
$error-bg: rgb(255, 247, 247);

@include container($street-address-input, column, column) {
  @extend %inputLabel;
}
@include container($zip-code-input, column, column) {
  @extend %inputLabel;
}
@include container($city-input, column, column) {
  @extend %inputLabel;
}
@include container($state-select, column, column) {
  @extend %inputLabel;
}
@include container($first-name-input, column, column) {
  @extend %inputLabel;
}
@include container($last-name-input, column, column) {
  @extend %inputLabel;
}
@include container($email-input, column, column) {
  @extend %inputLabel;
}
@include container($phone-input, column, column) {
  @extend %inputLabel;
}
.control-group.error {
  label {
    color: $error;
  }
  div.input > *, div.select > * {
    border-color: $error;
    background-color: $error-bg;
  }
}
div.input {
  input[type=text], input[type=number], input[type=email], input[type=tel] {
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 35px;
    width: 100%;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    padding: 10px;
    @include convertRem(height, 36px);
    @include border-radius(2.8px);
    transition: all .25s;
  }
}
div.select {
  position: relative;

  select {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    z-index: 5;
    position: relative;
    opacity: 1;
    cursor: pointer;
    padding: 0 0 0 .5em;
    width: 100%;
    @include border-radius(2.8px);
    @include convertRem(height, 36px);

    &::-ms-expand {
      display: none;
    }
    &:focus::-ms-value {
      background-color: transparent;
      color: rgba(0, 0, 0, .666);
    }
    .control-group.error & {
      border-color: $error;
      background-color: $error-bg;
    }
  }
  &:after {
    position: absolute;
    content: "\f3d0";
    font-family: ionicons;
    color: rgba(128, 128, 128, 0.666);
    height: 20px;
    width: 20px;
    top: 0;
    right: 15px;
    font-size: 28px;
    z-index: 6;
  }
}
select {
  background: 0;
  color: rgba(0, 0, 0, .666);
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
