body.protectAmerica, body.lightForm {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: transparent;

  .formly {
    margin: 0px auto !important;
  }
  .formly-field {
    border: none !important;
  }
  form [data-page][data-page="/consent"] {
    padding: 0px !important;
    text-align: center !important;
    font-size: 1px !important;
  }
  .formly-field-TCPA {
    text-align: center!important;
    line-height: 1!important;
    > label {
      font-size: 10px !important;
      color: #FFFFFF !important;
      font-weight: 300!important;
      text-transform: none!important;
      line-height: 1!important;
      width: 95%;
      padding: 0px;
      position: initial !important;
      > input {
        display: none !important;
      }
      span {
        display: none;
      }
    }
  }
  .row {
    margin-bottom: 16px;

    > .field.control-group {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0px !important;
      background: transparent !important;
      height: 100% !important;

      &:before {
        display: none;
      }
      &.error {
        > label {
          color: red!important;
        }
        input {
          border-color: red!important;
        }
      }
      label {
        position: initial;
        flex: 0 0 40%;
        background-color: transparent;
        color: #FFFFFF;
        transition: all .25s;
        font-size: 14px;
      }
      .input {
        flex: 1 1 auto;

        input {
          height: 38px;
          padding: 0px 8px;
          background-color: #FFFFFF;
          border-radius: 4px;
          transition: all .25s;
          color: #080401;
          border: 1px solid #080401;
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: .8px !important;

          @include placeholder {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            letter-spacing: 0.1px !important;
            font-weight: 400;
          }
          &:focus {
            border-color: #007AF7;
          }
        }
      }
    }
  }
  button.btn.next {
    display: block;
    font-weight: 600;
    background: #26ABE4 !important;
    border-color: #26ABE4 !important;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 4px;
    width: 95%;
    font-family: 'Roboto', sans-serif;
    font-size: 17px !important;
    height: 45px !important;
    line-height: 1;
    letter-spacing: 0.24px !important;
    margin: 0 auto 16px!important;
    padding: 0;
    transition: all .25s;
    &:hover {
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    }
  }
  .headline-cta, .form-headline {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-bottom: 16px;
  }
  .headline-cta {
    width: 100%;
    padding: 3px;

    > * {
      line-height: 1;
    }
  }
  .cta-eyebrow {
    text-transform: uppercase;
    padding: 6px;
    font-weight: 600;
  }
  .headline-wrapper {
    padding: 10px;

    .headline, .sub-headline {
      font-weight: 600;
    }
  }
  .form-headline {
    font-weight: 600;
  }
  label.formErrorBanner {
    padding: 0px!important;
    font-weight: 600!important;
    color: red!important;
    font-family: 'Roboto', sans-serif!important;
    text-align: center!important;
    transition: all .25s;
    line-height: 1!important;
    background-color: transparent!important;
    margin-bottom: 3px!important;
  }
  #thank-you-content {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-weight: 500;

    .headline, .sub-headline {
      padding: 4px 8px;
    }
  }
}
