// Layouts
// =======
// Sticky Footer
#main-content {
  border-top: 1px solid rgb(237, 237, 237);
  flex: 1;
  padding-top: $nav-height;
}

#site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

#transformer {
  flex: 1 0 auto;
  background-color: #FFFFFF;
}

#sitewide-header + section:first-of-type {
    max-width: 1390px;
    width: 95%;
    margin: 0px auto;
  }

body {
  background: $gray-50;
  #site-container {
    max-width: $site-max-width;
    width: 100%;
    margin: 0 auto;
  }
}

body.widget.iframed {
  background: transparent;
}

.inner-main-content {
  margin: 0 auto;
  > section:not(.section-full-width) {
    max-width: 60rem;
    margin: 0 auto;
  }
}

body.full-width {
  .inner-main-content {
    max-width: 100%;
  }
}

.footer-disclosure {
  font-family: sans-serif;
  font-size: 0.7rem;
  padding: 0;
  padding-top:3rem;
  display: block;
}

@include bp(tablet-up) {
  .footer-disclosure {
    font-family: sans-serif;
    font-size: 0.7rem;
    padding: 0 1rem;
    display: block;
    padding-left:2.5rem;

  }

  body {
    #site-container {
      border-left: 0.0556rem solid $gray-200;
      border-right: 0.0556rem solid $gray-200;
    }
  }
}

.star_bg {
  background-color: $margo;
  height: 16px;
  width: 0px;
  margin-bottom: 1rem;
  svg {
    max-width: none;
    width: 100px !important;
    height: 30px;
    margin-top: -7px;
  }
}
body.vertical-page #site-container .inner-main-content,
body.home #site-container .inner-main-content {
  max-width: 100%;
  background-color: $white-smoke;
}
