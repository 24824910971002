.c-gateway-cta {
  &__shell {
    margin: 0 auto 30px;
    display: block;
    width: 90%;
    max-width: 700px;
    min-height: 250px;
    font-size: 14px;
    color: #464646;
    background: none !important;
    padding: 0;
    text-decoration: none;

    @include bp(tablet-landscape-up) {
      display: flex;
      max-width: 1020px;
      min-width: 768px;
      flex-direction: row!important;
      align-items: flex-start;
    }
    &:focus,
    &:hover {
       cursor: pointer;
      h2 {
        color: $fg-link-hover-color;
      }
      .c-gateway-cta__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, .25);
        transition: all 0.25s linear;
      }
    }
  }
  &__image {
    min-height: 250px;
    display: block;
    outline: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    @include bp(tablet-landscape-up) {
      width: 50%;
      min-height: 250px;
    }
  }
  &__info {
    padding: 25px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    @include bp(tablet-landscape-up) {
      width: 50%;
      max-width: 514px;
      align-self: stretch;
      padding: 30px;
    }

    h2, h2 > a {
      font: 800 20px/28px Roboto;
      margin: 0 0 12px;
      transition: all 0.25s ease;
      text-decoration: none;

      &:hover {
        color: $fg-link-hover-color;
        cursor: pointer;
      }
      @include bp(tablet-up) {
        font: 800 28px/28px Roboto;
      }
    }
    .o-button-shell {
      display: none !important;
    }
  }
  &__byline {
    display: flex;
    align-items: center;
    font: 700 13px/13px Raleway;
    margin-bottom: 15px;
    color: #000;

    &--byline {
      text-transform: uppercase
    }

    &--photo {
      height: 40px;
      width: 40px;
      border-radius: 50px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.50);
      margin-right: 10px;
    }
  }
  &__teaser {
    font: 400 16px/20px 'Roboto';
    margin-bottom: 0;
    @include bp(tablet-up) {
      font: 300 18px/22px 'Roboto';
    }
  }
}
