#margo-logo,
.brand-logo {
    height: inherit;
    fill: $color-primary;
    width: 4rem;
    outline: none;
    margin: 0;
}

.brand-logo {
  display: flex;
}

#footer {
    .brand-logo {
        height: 3rem;
    }
}
