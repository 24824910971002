#eo-interstitial {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #266990;
    h3 {
        text-align: center;
    }
    h2 {
        margin: 0px auto;
        margin-bottom: 2rem;
    }
}

/*#eo-interstitial:before {
    content: '\f29C';
    font-family: "Ionicons";
    font-size: 36px;
    line-height: 1;
    -webkit-animation: spin 1s infinite;
    animation: spin 1s infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}*/

.thankyou-wrapper {
    padding-top: 2rem;
}
@media all and (min-width: 48em) {
    .thankyou-wrapper {
        padding-top: 0;
        height: calc(100vh - 15rem);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 39rem;
    }
}

.thankyou-wrapper .inner {
    text-align: center;
    max-width: 50rem;
    margin-bottom: 5rem;
}


.thankyou-wrapper h4,
.thankyou-wrapper p {
    margin-bottom: 4rem;
}


[data-type=EO] div#content,
[data-type=EO] div#content {
  display: block;
  height: auto;
  max-width: 1200px;
  margin: auto;
  padding-top: 25px;
  overflow: hidden;
}

div[data-type=EO] {
  background-color: #fff;
}

div[data-type=EO] * {
  vertical-align: middle;
}

div[data-type=EO] #appSteps {
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  overflow: auto;
}

div[data-type=EO] #appSubmitted {
  background-color: #e1f6d2;
  border: 1px solid #dae4d2;
  color: #005e29;
}

div[data-type=EO] #closeWarning {
  background-color: #ffd9d9;
  border: 1px solid #e9baba;
  color: #752325;
  margin-top: 7px;
  text-transform: uppercase;
}

div[data-type=EO] #closeWarning .sprite {
  background-position: 0 -28px;
}

div[data-type=EO] #appSubmitted,
div[data-type=EO] #closeWarning {
  border-radius: 4px;
  font-size: 1em;
  font-weight: bolder;
  padding: 7px 1% 7px 0;
  overflow: auto;
  text-align: center;
}

div[data-type=EO] #appSubmitted div,
div[data-type=EO] #closeWarning div {
  display: inline-block;
  float: left;
  width: 75%;
}

div[data-type=EO] #appSubmitted .sprite,
div[data-type=EO] #closeWarning .sprite {
  background-image: url('//dn3nmb5yt1ysw.cloudfront.net/files/2013/01/redirect-sprite-8-s.png');
  background-repeat: no-repeat;
  display: inline-block;
  float: left;
  height: 25px;
  margin: 0 2%;
  position: relative;
  width: 25px;
}

div[data-type=EO] #greeting {
  margin-top: 25px;
}

div[data-type=EO] #greeting > h2 {
  color: #0e76bc;
  font-size: 1.2em;
}

div[data-type=EO] #greeting > h2,
div[data-type=EO] #greeting > h4 {
  text-align: center;
}

div[data-type=EO] #notifications {
  overflow: auto;
}

div[data-type=EO] #redirectContainer {
  border-radius: 4px;
  background-color: #fff;
  border: 0;
  margin: auto;
  max-width: 720px;
  padding: 20px 3.5%;
  width: 85%;
}

div[data-type=EO] #stepOne .sprite,
div[data-type=EO] #stepThree .sprite {
  background-color: transparent;
  background-image: url("//dn3nmb5yt1ysw.cloudfront.net/files/2013/01/redirect-sprite-8-s.png");
  background-repeat: no-repeat;
  height: 113px;
  width: 113px;
}

div[data-type=EO] #stepOne .sprite {
  background-position: -25px 0;
}

div[data-type=EO] #stepTwo .sprite {
  background-image: url("//dn3nmb5yt1ysw.cloudfront.net/files/2013/01/Processing-GIF.gif");
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 113px;
  width: 113px;
}

div[data-type=EO] #stepTwo h3 span {
  display: none;
}

div[data-type=EO] #stepThree .sprite {
  background-position: -139px 0;
}

div[data-type=EO] #terms {
  border-top: 1px solid #c6c6c6;
  color: #585858;
  font-size: .75em;
  overflow: auto;
  line-height: 1.7;
}

div[data-type=EO] #terms img {
  display: block;
  margin: 15px auto;
}

div[data-type=EO] #terms p {
  margin-top: 20px;
}

@media screen and (min-width: 500px) {
  div[data-type=EO] #appSubmitted,
  div[data-type=EO] #closeWarning {
    font-size: 1em;
  }
}

@media screen and (min-width: 550px) {
  div[data-type=EO] #appSubmitted,
  div[data-type=EO] #closeWarning {
    display: inline-block;
    width: 45%;
  }

  div[data-type=EO] #appSubmitted .sprite,
  div[data-type=EO] #closeWarning .sprite {
    float: left;
  }
}

@media screen and (min-width: 650px) {
  div[data-type=EO] #appSubmitted {
    float: left;
    margin-left: 3%;
    font-size: 1em;
  }

  div[data-type=EO] #appSteps * {
    color: #bebebe;
  }

  div[data-type=EO] #appSteps div {
    display: inline-block;
    width: 33%;
    vertical-align: top;
  }

  div[data-type=EO] #appSteps #stepTwo * {
    color: #000;
  }

  div[data-type=EO] #closeWarning {
    bottom: 0;
    margin-top: auto;
    margin-right: 3%;
    position: absolute;
    right: 0;
    top: 0;
  }

  div[data-type=EO] #greeting {
    clear: both;
  }

  div[data-type=EO] #greeting > h2 {
    font-size: 1.5em;
  }

  div[data-type=EO] #greeting > h4 {
    font-size: .9em;
    margin: auto;
  }

  div[data-type=EO] #notifications {
    margin: auto;
    position: relative;
  }

  div[data-type=EO] #stepOne,
  div[data-type=EO] #stepTwo h3 span,
  div[data-type=EO] #stepThree {
    display: inline;
  }

  div[data-type=EO] #stepOne,
  div[data-type=EO] #stepTwo,
  div[data-type=EO] #stepThree {
    float: left;
  }

  div[data-type=EO] #redirectContainer {
    border: 1px solid #c6c6c6;
    max-width: 800px;
    width: 92.5%;
  }

  /*div[data-type=EO] #terms div {
    display: inline-block;
    float: left;
    width: 21%;
  }*/

  div[data-type=EO] #terms div img {
    display: none;
  }

  div[data-type=EO] #terms p {
    display: inline-block;
    /*float: right;*/
    width: 80%;
    margin: 0 10%;
    font-size:1em;
  }

  div[data-type=EO] .sprite {
    display: inline-block;
    position: relative;
  }
}

