.o-hamburger-container {
    position: relative;
    width: 3rem;
    @include bp(tablet-up) {
        display: none;
    }
}

.o-hamburger {
    cursor: pointer;
    width: 2.667rem;
    height: 2.667rem;
    transition: all 0.25s;
}

.o-hamburger__bottom-bun,
.o-hamburger__top-bun {
    content: '';
    display: block;
    position: absolute;
    left: 0.8533rem;
    width: 1rem;
    height: 0.05556rem;
    background: $color-primary;
    transform: rotate(0);
    transition: all 0.25s;
}

.o-hamburger:hover [class*="-bun"] {
    background: #999;
}

.o-hamburger__top-bun {
    top: 1.295rem;
    transform: translateY(-0.2222rem);
}

.o-hamburger__bottom-bun {
    bottom: 1.295rem;
    transform: translateY(0.2222rem);
}

#transformer.is-open {
    .o-hamburger {
        transform: rotate(90deg);

        .o-hamburger__top-bun {
            transform: rotate(45deg) translateY(0);
        }

        .o-hamburger__bottom-bun {
            transform: rotate(-45deg) translateY(0);
        }
    }
}
