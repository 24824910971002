body.modal-open {
  overflow: hidden;
}

.dialog-container{
  display: none;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  iframe {
    display:none;
  }
}

.modal-iframe {
  display:none;
}

.dialog-container.visible{
  visibility: visible;
  iframe {
    display: block !important;
  }
}

.modal{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(50, 50, 50, 0.2);
  z-index: 100;
}

.dialog-container.visible .dialog{
  opacity: 1;
  display: block;
}

.dialog{
  position: relative;
  background-color: white;
  padding: 0;
  width: 90%;
  height: 80vh;
  max-width: 100%;
  margin: auto;
  opacity: 0;
  display: none;
  transition: opacity .8s ease-in-out;
  -moz-transition: opacity .8s ease-in-out;
  -webkit-transition: opacity .8s ease-in-out;
  padding-bottom: 10px;
}

.dialog-head,
.dialog-body{
  padding: 10px 30px;
}

.dialog-head{
  margin: 0;
  border-bottom: solid 1px #999999;
}

.dialog-close, 
.dialog-close:before{
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 40px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  border: none;
  background-color: transparent;
}

.dialog-close{
  text-indent: -1000px;
  overflow: hidden;
  z-index: 101;
  cursor: pointer;
  background: white;
  border-radius: 15px 15px 0 0;
  margin-top: -30px;
  border: 1px solid gray;
  border-bottom: 0px;
}

.dialog-close:before{
  content: "\00D7";
  font-size: 30px;
  text-indent: 0;
}

.dialog-body{
  padding-bottom: 0;
  overflow: hidden;
}

.dialog iframe{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  border: 0px;
  height:80vh;
  width: 100%;
}
