.thankyou-wrapper {
  padding: 1rem 1rem;
  margin: 0px auto;
  text-align: center;
  color: #4D4D4D;

  @include bp(tablet-up) {
    padding: 2rem 1rem;
  }
  h2 {
    color: #02B875;
    margin: 0rem auto 2rem;
    font-weight: 100;
    font-size: 1.365rem;
    line-height: 1.8rem;

    @include bp(tablet-up) {
      line-height: 2.9rem;
      font-size: 2.3rem;
    }
  }
  h4 {
    @extend .p;
    margin: 0rem auto;
    font-size: .9rem;

    @include bp(tablet-up) {
      font-size: 1.1rem;
    }
  }
  p {
    margin: 0rem auto 1.3rem;
    font-size: .865rem;

    @include bp(tablet-up) {
      font-size: 1rem;
    }
  }
  a {
    color: #02B875;
    font-size: .865rem;
    cursor: pointer;
    border-bottom: 3px solid;
    text-decoration: none;
    outline: none;

    @include bp(tablet-up) {
      font-size: 1.1rem;
    }
    &:hover {
      background: rgba(2, 184, 117, 0.23);
    }
  }
  .social-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 3rem;

    h2 {
      flex: 1 1 100%;
      margin-bottom: .5rem;

      @include bp(tablet-up) {
        font-size: 2rem;
      }
    }
    a.social {
      @extend .social-icon;
      margin-top: 1rem;

      &.twitter {
        margin-left: 1rem;
      }
    }
  }
  @include bp(tablet-up) {
    height: calc(100vh-15rem);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 39rem;
  }
}
