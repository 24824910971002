#simple-header {
  display: flex;
  text-align: center;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  position: relative;


  ul.checklist {
    li {
      list-style-image: url("https://cdn0.iconfinder.com/data/icons/social-messaging-ui-color-shapes/128/check-circle-green-512.png");
    }
  }
  > *:last-child {
    margin-bottom: 0;
  }
  h1, .h1 {
  }
  &.image {
    position: relative;
    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0.5;
      content: '';
    }
    h1, .h1, .eyebrow {
      text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.28);
    }
  }
}
