#LeadRequestHomeRefinanceExistingMortgages0RateType, .radios {
  label.radio-label {
    display:inline-block;
    font-size:1em;
  }
}

label.radio-label {
  margin: 14px;
  display: flex;
  cursor: pointer;
  color: #111;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;

  @include transition(all 300ms);

  @include breakpoint(768) {
    font-size: 21px;
  }
  input[type=radio] {
    display: none;
    outline: 0;
    font-size: 2em;
    cursor: pointer;
  }
  .overlay {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    &:after {
      font-family: Ionicons;
      content: '\f401';
      font-size: 2rem;
      line-height: 2.4rem;
      color: #24b6fe;
      display: inline-block;
      zoom: 1;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
  input:checked ~ .overlay:after {
    font-family: Ionicons;
    content: '\f400';
  }
}
// body label.radio input:checked+.overlay:before, html label.radio input:checked+.overlay:before {
    // font-family: Ionicons;
    // content: '\f400';
// }

// body label.radio .overlay:before, html label.radio .overlay:before {
    // font-family: Ionicons;
    // content: '\f401';
    // font-size: 2rem;
    // line-height: 2.4rem;
    // color: #24b6fe;
    // display: inline-block;
    // zoom: 1;
// }

// body label.radio .overlay, html label.radio .overlay {
    // position: relative;
    // display: inline-block;
    // vertical-align: middle;
// }

// body label.radio input[type=radio], body label.radio input[type=checkbox], html label.radio input[type=radio], html label.radio input[type=checkbox] {
    // outline: 0;
    // font-size: 2em;
    // cursor: pointer;
// }

#Refinance, #NewPurchase {
  .overlay:after {
    content: '';
  }
  -webkit-overflow-scrolling: touch;
  cursor: pointer;
  -webkit-flex: 1;
  flex: 1 1 0px;
  border: 2px solid $accent;
  background-color: #fff;
  background-clip: border-box;
  min-height: 140px;
  @include border-radius(5px);

  .overlay {
    order: -1;
    display: block;
    background-color: transparent;
    font-weight: 600;
    font-size: 21px;
    padding: 14px;
    z-index: 1;

    @include breakpoint(768) {
      font-size: 24.5px;
    }
  }

  .radio-image {
    width: 100%;
    flex: 1 1 75px;
    height: auto;
    display: block;
    border-bottom: 14px solid transparent;

    @include breakpoint(768) {
      flex: 1 1 150px;
    }
  }
}

#Refinance {
  .radio-image {
    background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/house-icon-refi-dark.png") no-repeat center;
    background-size: contain;
  }

  &:hover .radio-image, input:checked + .radio-image {
    background: $accent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/house-icon-refi-white.png") no-repeat center;
    background-size: contain;
    border-color: $accent;
    box-shadow: 0 14px 21px -7px rgba(0, 0, 0, 0.3);

    @include transition(box-shadow 200ms);
  }

  &:hover .overlay, input:checked ~ .overlay {
    color: #fff;
    background-color: $accent;

    @include transition(color 200ms);
  }
}

#NewPurchase {
  .radio-image {
    background: transparent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/house-icon-forsale-dark.png") no-repeat center;
    background-size: contain;
  }

  &:hover .radio-image, input:checked + .radio-image {
    background: $accent url("//dn3nmb5yt1ysw.cloudfront.net/images/common/house-icon-forsale-white.png") no-repeat center;
    background-size: contain;
    border-color: $accent;
    box-shadow: 0 14px 21px -7px rgba(0, 0, 0, 0.3);

    @include transition(box-shadow 200ms);
  }

  &:hover .overlay, input:checked ~ .overlay {
    color: #fff;
    background-color: $accent;

    @include transition(color 200ms);
  }
}
