@mixin crSlider($cr, $color) {
  ##{$cr} {
    span {
    background-color: lighten($color, 10%);
    @include transition(background-color 0.25s, font-weight 0.1s linear);

    }
    &:hover span, input:checked + div > span {
      background-color: $color;
      font-weight: 600;
      color: #fff;
    }
  }
}

#LeadApplicantInfoCreditSelfRating {
  .overlay:after {
    content: '';
  }
  -webkit-overflow-scrolling: touch;
  cursor: pointer;

  @include crSlider(Excellentbr740, #69a059);

  @include crSlider(Goodbr650740, #71b956);

  @include crSlider(Fairbr580650, #f18136);

  @include crSlider(PoorbrBelow580, #cf4539);

  @include breakpoint(768) {
    label {
      &:first-of-type span {
        @include border-left-radius(100px);
      }
      &:last-of-type span {
        @include border-right-radius(100px);
      }
    }
  }
  .buttonToggle {
    flex: 1;
    -webkit-overflow-scrolling: touch;
    cursor: pointer;
    margin: 3px;
    input[type=radio] {
      display: none;
    }
    span {
      font-size: 17.36px;
      color: #666;
      flex: 1;
      padding:10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

