html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
}
.h1, h1 {
    font-family: "Raleway", sans-serif;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.20;
    opacity: 1.00;
    margin-top: 0;
    margin-bottom: 0.5556rem;
    letter-spacing: -0.05556rem;
    word-spacing: 0;
    text-transform: none;
}
@include bp(tablet-up) {
    .h1, h1 {
        font-size: 2.944rem;
    }
}
.h2, h2 {
    font-family: "Roboto Slab", serif;
    max-width: 30rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.20;
    opacity: 1.00;
    margin: 0.2778rem 0;
    letter-spacing: normal;
    word-spacing: 0;
    text-transform: none;

    @include bp(tablet-up) {
        font-size: 2rem;
    }
}
.h3, h3 {
    font-family: "Roboto", sans-serif;
    font-size: 1.111rem;
    font-weight: 700;
    line-height: 1.20;
    opacity: 1.00;
    margin-top: 1.667rem;
    margin-bottom: 0.2778rem;
    letter-spacing: 0;
    word-spacing: 0;
    text-transform: none;
}
.a, .li, .p, a, li, p {
    font-family: "Roboto", sans-serif;
    font-size: $font-size;
    line-height: 1.60;
    letter-spacing: -0.01rem;
    word-spacing: -0.0166rem;
}
.p, p {
    text-transform: none;
    font-weight: 400;
    opacity: 1;
    margin-top: 0;
    margin-bottom: 1.3rem;

    &.accent {
        margin-bottom: 0;
        font-weight: 700;
        opacity: 1;
    }
    &--solo {
        margin-bottom: 0;
        line-height: 1;
    }
    span {
        opacity: 1;
    }
}
.a, a {
    opacity: 1;
    color: inherit;
    outline: none;
}
.material-icons.md-18 {
    font-size: 18px;
}
.material-icons.md-24 {
    font-size: 24px;
}
.material-icons.md-36 {
    font-size: 36px;
}
.material-icons.md-48 {
    font-size: 48px;
}
.content-header {
    font-size: 1.25rem;
    border-bottom: 1px solid $gray-300;
    width: 100%;
    display: block;
    font-weight: 300;
    margin: 1rem 0rem;
    max-width: 100%;
}
ol, ul {
    list-style: disc;
    margin-bottom: 1rem;
    li {
    }
}
