/* Social mixin */
@mixin social-icon($icon-color:#fafafa) {
    .svg {
        fill: $icon-color;
    }
    &:before {
        background-color: $icon-color;
    }
}
.social {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    background-color: $color-white;
    padding: 0;
    border-radius: 50%;
    -webkit-transition: all 250ms;
    -moz-transition: all 250ms;
    transition: all 250ms;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
    margin: 0 0.5rem;
}
@include bp(desktop-up) {
    .social:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.2);
        -webkit-transform: translateY(-4px);
        -moz-transform: translateY(-4px);
        transform: translateY(-4px);

        .svg {
            fill: $color-white;
        }
    }
    .social:hover:before {
        transition: all 0.25s ease;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .social:hover .social-icon {
        transition: all 0.25s ease;
        fill: $color-white;
    }
}
.social:focus {
    outline: none;
}
.social:before {
    position: absolute;
    transition: all 0.25s ease;
    display: block;
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    z-index: -1;
}
.social-icon {
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg {
        width: 60%;
    }
}
.social-widget {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    a {
        &:hover {
            cursor: pointer;
        }
    }
    span {
        height: 100%;
        width: 65%;
        background-size: contain;
        background-position: center center;
    }
}
.instagram {
    @include social-icon($instagram);
}
.facebook {
    @include social-icon($facebook);
}
.twitter {
    @include social-icon($twitter);
}
.linkedin {
    @include social-icon($linkedin);
}
.soundcloud {
    @include social-icon($soundcloud);
}
.youtube {
    @include social-icon($youtube);
}
.email {
    @include social-icon($email);
}
.pinterest {
    @include social-icon($pinterest);
}
