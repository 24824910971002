.o-button-shell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}
.o-button {

    padding: 0.75rem 2rem;
    background: $color-primary;
    color: $color-white;
    border: .0556rem solid $color-primary;
    border-radius: 1.25rem;
    line-height: 1;
    transition: all 0.25s;
    text-decoration: none;
    margin: 0;
    &:hover {
        cursor: pointer;
        background: darken($color-primary, 3%);
        border-color: darken($color-primary, 3%);
    }
    &--mobile-block {
        @extend .o-button;
        border-radius: 0;
        margin: .5rem 0;
        @include bp(tablet-up) {
            border-radius: 1.25rem;
        }
    }
    &--alt {
        @extend .o-button;
        background: transparent;
        color: $color-primary;

        &:hover {
            cursor: pointer;
            color: $color-white;
            background: $color-primary;
            border-color: $color-primary;
        }
    }
    &__text {
        line-height: 1;
        text-align: center;
    }
}
