
@mixin breakpoint($point) {
  @if $point == papa-bear {
    @media (min-width: 1600px) {
      @content;
    }
  }
  @else if $point == mamma-bear {
    @media (min-width: 1250px) {
      @content;
    }
  }
  @else if $point == baby-bear {
    @media (min-width: 650px) {
      @content;
    }
  }
  @else if $point == baby-mamma-bear {
    @media (min-width: 650px) and (max-width: 880px) {
      @content;
    }
  }
  @else if $point == foxy-bear {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
      @content;
    }
  }
  @else {
    @media only screen and (min-width: $point + px) {
      @content;
    }
  }
}

@mixin transition($val...) {
  -webkit-transition: $val;
  -moz-transition: $val;
  -o-transition: $val;
  transition: $val;
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Single side border-radius

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}


@mixin radioImage($name, $size: false) {
  background: url(#{$images-path}/#{$name}.png) no-repeat center;
  background-color: transparent;

  @if $size {
    -webkit-background-size: $size;
    -moz-background-size: $size;
    -o-background-size: $size;
    background-size: $size;
  }

  @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    background-image: url(#{$images-path}/#{$name}#{$retina-suffix}.png);
  }
}

@mixin background-size($size) {
  -webkit-background-size: $size;
  -moz-background-size: $size;
  -o-background-size: $size;
  background-size: $size;
}

@mixin box-shadow($horiz: 0.5em, $vert: 0.5em, $blur: 0px, $spread: 0px, $color: #000000) {
  -webkit-box-shadow: $horiz $vert $blur $spread $color;
  -moz-box-shadow: $horiz $vert $blur $spread $color;
  box-shadow: $horiz $vert $blur $spread $color;
}


@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin convertRem($property, $size) {
  $property: $size;
  $property: calculateRem($size);
}
