@import 'headline-simple';

.tiny {
  padding: 1rem 0rem;
  min-height: 6rem;

  h1 {
    font-size:1.5rem;
  }
}
.small {
  padding: 1rem 0rem;
  min-height: 13rem;
}
.medium {
  padding: 2rem 0rem;
  min-height: 18rem;
}
.large {
  padding: 4rem 0rem;
  min-height: 30rem;
}
.x-large {
  padding: 4rem 0rem;
  min-height: 40rem;
}
