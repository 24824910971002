#off-canvas {
    position: fixed;
    overflow: hidden;
    width: 17rem;
    top: $nav-height;
    left: -17rem;
    height: 100%;
    padding: 0 0 1rem 1rem;
    bottom: 0;
    background: $gray-50;
    z-index: 50;
    transition: all 0.25s;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    .o-sitemap__link {
      padding: 0.4rem 0;
    }
    .off-canvas__nav {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        height: 95%;
    }
}

#transformer {
    transition: all 0.25s;

    &.is-open {
        #off-canvas {
            left: 0;
        }
    }
}

#site-container.is-open {
    overflow: hidden;
    position: fixed;
}
