// Colors
$lava: #E81617;
$azureish-white: #D0E5F1;
$pp-header-color: #FFFFFF;
$pp-full-bg-color: $white-smoke;
$pp-section-headline-bg: $lava;
// Measurements
$base-font-size: 16px;
$content-max-width: 60rem;
$small-icon-size: 50px;
$medium-icon-size: 100px;
$large-icon-size: 150px;
$x-large-icon-size: 250px;
// Fonts & Shit
$cta-primary-font: 'Montserrat', sans-serif;
$pp-serif-font: 'Roboto Slab', serif;

> * {
  font: normal $base-font-size/1.4 sans-serif;
}
@media screen {
  %container {
    margin: 0 auto;
    max-width: $content-max-width;
    position: relative;

    > div {
      padding: $base-font-size/2;
    }
  }
}
.inner-main-content {
  background: $pp-full-bg-color;
  max-width: 100%;
}
@include bp(tablet-up) {
  .widget-container {
    margin-right: 2rem;
  }
  .ProductPageIconCopy {
    .widget-container {
      margin-right: 0rem;
    }
  }
}
