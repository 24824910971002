.formly-field-TCPA, .formly-field-Button + .formly-field-inject label {
  color: #999;
  font-size: 10.5px;
  margin: 10.5px auto;
  display: block;
  @include breakpoint(768) {
    max-width: 75%;
  }
  & a {
  font-size: 10.5px;
  color: #02B875;
  }
}

.formly-field-Button + .formly-field-inject img {
  width: 75%;
  max-width: 400px;
}
